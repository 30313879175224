import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";
import { SERVER_URL } from "../config";
import { CustomerContext } from "../Routes";
import $ from "jquery";

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = false;
  document.body.appendChild(script);
}

function HomepageHeader() {
  const history = useHistory();

  const [categories, setCategories] = useState(
    JSON.parse(localStorage.getItem("menu")) || []
  );
  const [categoryLoading, setCategoryLoading] = useState(false);
  const { state, dispatch } = useContext(CustomerContext);
  const { cart } = state;

  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
  const [contactDetails, setContactDetails] = useState({});
  const [contactDetailsLoading, setContactDetailsLoading] = useState(true);
  const [pathname, setPathname] = useState("");
  const [subtotal, setSubtotal] = useState("");
  const [socialMedia, setSocialMedia] = useState({});

  const [categoryBanners, setCategoryBanners] = useState({});
  const [imageSliderLoading, setCategoryBannerLoading] = useState(false);
  const [query, setQuery] = useState(null);

  // Get Image Sliders
  useEffect(() => {
    fetch(`${SERVER_URL}/settings`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCategoryBannerLoading(false);

        if (data.status == 200) {
          let banners = data.body.categoryBanner;
          let bannerObj = {};
          banners.map((item) => {
            bannerObj[item.category._id] = {
              image: item.image,
              webpageUrl: item.webpageUrl,
            };
          });
          // console.log(bannerObj);
          setCategoryBanners(bannerObj);
          setSocialMedia(data.body.socialMedia);
          setContactDetails(data.body.contactUs);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setCategoryBannerLoading(false);
        // toast.warning(error);
      });
  }, []);

  // Calculate amount
  useEffect(() => {
    // Calculate subtotal
    let sub_total = cart
      .map((product) => product.price * product.quantity)
      .reduce((prev, curr) => prev + curr, 0);

    setSubtotal(sub_total);
  }, [cart]);

  // Get All Parent with sub Categoies
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("menu"))) {
      setCategoryLoading(true);
    }
    fetch(`${SERVER_URL}/parentCategories?allSubCategories=true`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCategoryLoading(false);
        if (data.status == 200) {
          const sortedData = data.body.sort((a, b) =>
            a.position > b.position ? 1 : -1
          );
          setCategories(sortedData || []);
          localStorage.setItem("menu", JSON.stringify(sortedData));
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setCategoryLoading(false);
        toast.error(error);
      });
  }, []);

  useEffect(() => {
    if (customerInfo && customerInfo.jwtToken) {
      dispatch({ type: "CUSTOMER", payload: customerInfo.jwtToken });
    }

    if (customerInfo && customerInfo.cart.length) {
      dispatch({ type: "UPDATE_STATE", payload: customerInfo });
    }
  }, []);

  // For the active page
  useEffect(() => {
    const path = history.location.pathname.split("/");
    setPathname(`/${path[1]}`);
  }, []);

  // profileMenuHandler
  function profileMenuHandler(evt) {
    evt.preventDefault();
    $("#settings-menu-wrapper").toggleClass("active");
    $("#mini-cart").removeClass("active");
    $("body").addClass("active-overlay");
  }

  // cartMenuHandler
  function cartMenuHandler(evt) {
    evt.preventDefault();

    // $("#minicart-trigger").siblings("#mini-cart").toggleClass("active");
    $("#mini-cart").toggleClass("active");
    $("#settings-menu-wrapper").removeClass("active");
    $("body").addClass("active-overlay");
  }

  function removeOverlay() {
    $("#mini-cart").removeClass("active");
    $("#settings-menu-wrapper").removeClass("active");
    $("body").removeClass("active-overlay");
  }

  const logout = (evt) => {
    evt.preventDefault();
    removeOverlay();
    localStorage.removeItem("customerInfo");
    dispatch({ type: "CLEAR" });
    toast.success("Successfully Logout !!");
    history.push("/account/login");
  };

  // For the Navigation
  useEffect(function () {
    $("#mobile-menu-trigger").on("click", function () {
      $("#offcanvas-mobile-menu").removeClass("inactive").addClass("active");
    });

    $("#offcanvas-menu-close-trigger").on("click", function () {
      $("#offcanvas-mobile-menu").removeClass("active").addClass("inactive");
    });

    // $("#minicart-trigger").on("click", function (e) {
    //   e.preventDefault();

    //   $(this).siblings(".mini-cart").toggleClass("active");
    //   $("#settings-menu-wrapper").removeClass("active");
    //   $("body").addClass("active-overlay");
    // });

    // $("#header-settings-trigger").on("click", function (e) {
    //   e.preventDefault();
    //   $(this).siblings(".settings-menu-wrapper").toggleClass("active");
    //   $("#mini-cart").removeClass("active");
    //   $("body").addClass("active-overlay");
    // });

    /*Minicart Close When Click Outside Minicart*/

    $("body").on("click", function (e) {
      var $target = e.target;
      if (
        !$($target).is(
          ".header-cart-icon, .header-settings-icon, #currency-trigger, #language-trigger"
        ) &&
        !$($target)
          .parents()
          .is(".header-cart-icon, .header-settings-icon, #currency-trigger") &&
        $("body").hasClass("active-overlay")
      ) {
        $(
          ".mini-cart, .settings-menu-wrapper, #currency-menu, #language-menu"
        ).removeClass("active");
        $("body").removeClass("active-overlay");
      }
    });
  }, []);

  const mobileSubMenuHandler = (evt) => {
    evt.preventDefault();
    // set display none to all sub menu
    const innerMenu = evt.target.nextSibling;

    if (innerMenu.style.display) {
      innerMenu.style.display = null;
    } else {
      innerMenu.style.display = "none";
    }
  };

  return (
    <>
      {/*====================  header area ====================*/}

      {/* <div class="header-area header-area--transparent header-area--middle-logo header-sticky is-sticky"> */}
      <div class="header-area header-area--middle-logo header-sticky is-sticky">
        <div class="container wide">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  header wrapper  =======*/}
              <div class="header-wrapper d-none d-lg-flex">
                {/* menu wrapper */}
                <div
                  class="navigation-menu-wrapper"
                  style={{ flexBasis: "90%" }}
                >
                  <nav>
                    <ul className="mega-menu-new">
                      {/* <li>
                        <Link to="/">HOME</Link>
                      </li> */}

                      {categoryLoading ? (
                        <Spinner />
                      ) : (
                        categories.map((item, index) => {
                          return (
                            <li class="menu-item-has-children" key={index}>
                              <Link to={`/${item.slug}`}>{item.name}</Link>
                              <ul class="mega-menu four-column mega-menu-last">
                                {item.subCategories.map((subItem, subIndex) => {
                                  return (
                                    <li key={subIndex}>
                                      <Link
                                        to={`/${item.slug}/${subItem.slug}`}
                                      >
                                        {subItem.name}
                                      </Link>
                                      <ul>
                                        {subItem.childCategories.map(
                                          (childItem, childIndex) => {
                                            return (
                                              <li key={childIndex}>
                                                <Link
                                                  to={`/${item.slug}/${subItem.slug}/${childItem.slug}`}
                                                >
                                                  {childItem.name}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </li>
                                  );
                                })}

                                <li class="megamenu-banner d-none d-lg-block mt-30 w-100">
                                  {categoryBanners[item._id] ? (
                                    <Link
                                      to={categoryBanners[item._id].webpageUrl}
                                      class="mb-0"
                                    >
                                      {/* {console.log(setCategoryBanners)} */}

                                      <img
                                        width="920"
                                        height="183"
                                        src={categoryBanners[item._id].image}
                                        class="img-fluid"
                                        alt=""
                                      />
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              </ul>
                            </li>
                          );
                        })
                      )}

                      {/* <li class="menu-item-has-children">
                          <a href="#">Serveware</a>
                          <ul class="mega-menu four-column">
                            <li>
                              <a href="#">Barware </a>
                              <ul>
                                <li>
                                  <a href="#">Ice Bucket</a>
                                </li>
                                <li>
                                  <a href="#">Wine Cooler</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Desserts & Appetisers </a>
                              <ul>
                                <li>
                                  <a href="#">Bowl</a>
                                </li>
                                <li>
                                  <a href="#">Platter</a>
                                </li>
                                <li>
                                  <a href="#">Cake Stand</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Coffee/tea </a>
                              <ul>
                                <li>
                                  <a href="#">Mug</a>
                                </li>
                                <li>
                                  <a href="#">Milk & Sugar Pot</a>
                                </li>
                                <li>
                                  <a href="#">Coffee Set</a>
                                </li>
                                <li>
                                  <a href="#">Coffee Filter</a>
                                </li>
                              </ul>
                            </li>
                            <li class="menu-item-has-children">
                              <a href="#">Accessories </a>
                              <ul>
                                <li>
                                  <a href="#">Napkin Holder</a>
                                </li>
                                <li>
                                  <a href="#">Bottle Stopper</a>
                                </li>
                              </ul>
                            </li>
                            <li class="megamenu-banner d-none d-lg-block mt-30 w-100">
                              <a href="shop-left-sidebar.html" class="mb-0">
                                <img
                                  width="920"
                                  height="183"
                                  src="/assets/img/banners/img-bottom-menu.webp"
                                  class="img-fluid"
                                  alt=""
                                />
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li class="menu-item-has-children">
                          <a href="#">Furniture & Lighting</a>
                          <ul class="mega-menu four-column">
                            <li>
                              <a href="#">Lights </a>
                              <ul>
                                <li>
                                  <a href="#">Pendant Lamp</a>
                                </li>
                                <li>
                                  <a href="#">Table Lamp</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Accent </a>
                              <ul>
                                <li>
                                  <a href="#">Bench</a>
                                </li>
                                <li>
                                  <a href="#">Table</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Living </a>
                              <ul>
                                <li>
                                  <a href="#">Writing Table</a>
                                </li>
                                <li>
                                  <a href="#">Coffee Table</a>
                                </li>
                              </ul>
                            </li>
                            <li class="menu-item-has-children">
                              <a href="#">Bedroom </a>
                              <ul>
                                <li>
                                  <a href="#">Side Table</a>
                                </li>
                              </ul>
                            </li>
                            <li class="megamenu-banner d-none d-lg-block mt-30 w-100">
                              <a href="shop-left-sidebar.html" class="mb-0">
                                <img
                                  width="920"
                                  height="183"
                                  src="/assets/img/banners/img-bottom-menu.webp"
                                  class="img-fluid"
                                  alt=""
                                />
                              </a>
                            </li>
                          </ul>
                        </li> */}

                      {/* <li>
                        <Link to="/about">ABOUT</Link>
                      </li>

                      <li>
                        <Link to="/contact">CONTACT</Link>
                      </li> */}
                    </ul>
                  </nav>
                </div>
                {/* logo */}
                <div class="logo text-center">
                  <Link to="/">
                    <img
                      width="93"
                      height="25"
                      src="/assets/img/logo.png"
                      class="img-fluid"
                      alt=""
                    />
                  </Link>
                </div>

                {/* header icon */}
                <div class="header-icon-wrapper">
                  <ul class="icon-list">
                    <li>
                      <a href="javascript:void(0)" id="search-overlay-trigger">
                        <i class="ion-ios-search-strong"></i>
                      </a>
                    </li>
                    <li>
                      <div class="header-cart-icon">
                        <a
                          href="#"
                          id="minicart-trigger"
                          onClick={cartMenuHandler}
                        >
                          <i class="ion-bag"></i>
                          <span class="counter">
                            {customerInfo && customerInfo.cart.length}
                          </span>
                        </a>
                        {/* mini cart  */}
                        {customerInfo && customerInfo.cart.length ? (
                          <div class="mini-cart" id="mini-cart">
                            <div class="cart-items-wrapper ps-scroll">
                              <div className="">
                                {customerInfo &&
                                  customerInfo.cart.map((item, index) => {
                                    return (
                                      <div class="single-cart-item">
                                        <a
                                          href="javascript:void(0)"
                                          class="remove-icon"
                                          onClick={(evt) => {
                                            dispatch({
                                              type: "REMOVE_FROM_CART",
                                              payload: {
                                                productId: item.productId,
                                              },
                                            });
                                          }}
                                        >
                                          <i class="ion-android-close"></i>
                                        </a>
                                        <div class="image">
                                          <Link to={`/product/${item.slug}`}>
                                            <img
                                              width="80"
                                              height="106"
                                              src={item.image}
                                              class="img-fluid"
                                              alt=""
                                            />
                                          </Link>
                                        </div>
                                        <div class="content">
                                          <p class="product-title">
                                            <Link to={`/product/${item.slug}`}>
                                              {item.name}
                                            </Link>
                                          </p>
                                          <p class="count">
                                            <span> {item.quantity} x </span>{" "}
                                            {item.price}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                            <div class="cart-calculation">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td class="text-start">Sub-Total :</td>
                                    <td class="text-end">
                                      <i className="fa fa-inr"></i> {subtotal}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td class="text-start">Total :</td>
                                    <td class="text-end">
                                      <i className="fa fa-inr"></i> {subtotal}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="cart-buttons">
                              <Link to="/cart" onClick={removeOverlay}>
                                VIEW CART
                              </Link>
                              <Link to="/checkout" onClick={removeOverlay}>
                                CHECKOUT
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div class="mini-cart" id="mini-cart">
                            <div class="cart-items-wrapper ps-scroll">
                              <div className="alert alert-danger">
                                Cart is Empty !!
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                    <li>
                      <div class="header-settings-icon">
                        <a
                          href="javascript:void(0)"
                          class="header-settings-trigger"
                          id="header-settings-trigger"
                          onClick={profileMenuHandler}
                        >
                          <div class="setting-button">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </a>

                        {/* settings menu */}
                        <div
                          class="settings-menu-wrapper"
                          id="settings-menu-wrapper"
                        >
                          <div class="single-settings-block">
                            <h4 class="title">MY ACCOUNT </h4>
                            {state && state.jwtToken ? (
                              <ul>
                                <li>
                                  <Link
                                    to="/account/myAccount"
                                    onClick={removeOverlay}
                                  >
                                    My Account
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    onClick={removeOverlay}
                                    to="/account/myAccount/orders"
                                  >
                                    Orders
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    onClick={removeOverlay}
                                    to="/account/myAccount/wishlists"
                                  >
                                    Wishlist
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    onClick={removeOverlay}
                                    to="/account/myAccount/address"
                                  >
                                    Address
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={logout}>Logout</Link>
                                </li>
                              </ul>
                            ) : (
                              <ul>
                                <li>
                                  <Link
                                    to="/account/register"
                                    onClick={removeOverlay}
                                  >
                                    Register/Login
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/*=======  End of header wrapper  =======*/}

              {/*=======  mobile navigation area  =======*/}

              <div class="header-mobile-navigation d-block d-lg-none">
                <div class="row align-items-center">
                  <div class="col-6 col-md-6">
                    <div class="header-logo">
                      <Link to="/">
                        <img
                          width="93"
                          height="25"
                          src="/assets/img/logo.png"
                          class="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                  <div class="col-6 col-md-6">
                    <div class="mobile-navigation text-end">
                      <div class="header-icon-wrapper">
                        <ul class="icon-list justify-content-end">
                          <li>
                            <div class="header-cart-icon">
                              <Link to="/cart">
                                <i class="ion-bag"></i>
                                <span class="counter">
                                  {state && state.cart.length}
                                </span>
                              </Link>
                            </div>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              class="mobile-menu-icon"
                              id="mobile-menu-trigger"
                            >
                              <i class="fa fa-bars"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*=======  End of mobile navigation area  =======*/}
            </div>
          </div>
        </div>
      </div>

      {/*=======  offcanvas mobile menu  =======*/}

      <div class="offcanvas-mobile-menu" id="offcanvas-mobile-menu">
        <a
          href="javascript:void(0)"
          class="offcanvas-menu-close"
          id="offcanvas-menu-close-trigger"
        >
          <i class="ion-android-close"></i>
        </a>

        <div class="offcanvas-wrapper">
          <div class="offcanvas-inner-content">
            <div class="offcanvas-mobile-search-area">
              <form
                action="#"
                onSubmit={(evt) => {
                  evt.preventDefault();
                  document.querySelector("#search-overlay").style.display =
                    "none";
                  history.push(`/search?q=${query}`);
                }}
              >
                <input
                  type="search"
                  placeholder="Search ..."
                  onChange={(evt) => {
                    setQuery(evt.target.value);
                  }}
                />
                <button type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </form>
            </div>
            <nav class="offcanvas-navigation">
              <ul>
                <li class="menu-item-has-children">
                  <Link to="/">{`Home`.toUpperCase()}</Link>
                </li>

                {categories.map((category) => {
                  return (
                    <li class="menu-item-has-children" key={`${category.id}`}>
                      <Link onClick={mobileSubMenuHandler} to={`#`}>
                        {category.name}
                      </Link>

                      <ul class="sub-menu mobile-sub-menu">
                        {category.subCategories.map((subCategory) => {
                          return (
                            <li key={subCategory._id}>
                              <Link
                                to={`/${category.slug}/${subCategory.slug}`}
                              >
                                {subCategory.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
                <li class="menu-item-has-children">
                  <Link to="/about"> ABOUT</Link>
                </li>

                <li class="menu-item-has-children">
                  <Link to="/contact"> CONTACT </Link>
                </li>
              </ul>
            </nav>

            <div class="offcanvas-settings">
              <nav class="offcanvas-navigation">
                <ul>
                  {/* <li class="menu-item-has-children">
                    <a href="#">MY ACCOUNT </a>
                    <ul class="sub-menu">
                      <li>
                        <a href="login-register.html">Register</a>
                      </li>
                      <li>
                        <a href="login-register.html">Login</a>
                      </li>
                    </ul>
                  </li> */}

                  {state && state.jwtToken ? (
                    <li class="menu-item-has-children">
                      <Link to="/account/myAccount">MY ACCOUNT </Link>
                    </li>
                  ) : (
                    <li class="menu-item-has-children">
                      <Link to="/login">Register/Login </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>

            <div class="offcanvas-widget-area">
              <div class="off-canvas-contact-widget">
                <div class="header-contact-info">
                  <ul class="header-contact-info__list">
                    <li>
                      <i class="ion-android-phone-portrait"></i>{" "}
                      <a href={`tel:${contactDetails.mobile}`}>
                        {" "}
                        {contactDetails.mobile}{" "}
                      </a>
                    </li>
                    <li>
                      <i class="ion-android-mail"></i>{" "}
                      <a href={`mailto:${contactDetails.email}`}>
                        {contactDetails.email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/*Off Canvas Widget Social Start*/}
              <div class="off-canvas-widget-social">
                {socialMedia.facebook ? (
                  <a
                    target={"_blank"}
                    href={socialMedia.facebook}
                    title="Facebook"
                  >
                    <i class="fa fa-facebook"></i>
                  </a>
                ) : (
                  ""
                )}
                {socialMedia.twitter ? (
                  <a
                    target={"_blank"}
                    href={socialMedia.twitter}
                    title="Twitter"
                  >
                    <i class="fa fa-twitter"></i>
                  </a>
                ) : (
                  ""
                )}
                {socialMedia.linkedin ? (
                  <a
                    target={"_blank"}
                    href={socialMedia.linkedin}
                    title="LinkedIn"
                  >
                    <i class="fa fa-linkedin"></i>
                  </a>
                ) : (
                  ""
                )}
                {socialMedia.youtube ? (
                  <a
                    target={"_blank"}
                    href={socialMedia.youtube}
                    title="Youtube"
                  >
                    <i class="fa fa-youtube-play"></i>
                  </a>
                ) : (
                  ""
                )}
              </div>
              {/*Off Canvas Widget Social End*/}
            </div>
          </div>
        </div>
      </div>

      {/*=======  End of offcanvas mobile menu  =======*/}
      {/*====================  search overlay ====================*/}

      <div class="search-overlay" id="search-overlay">
        <a
          href="javascript:void(0)"
          class="close-search-overlay"
          id="close-search-overlay"
        >
          <i class="ion-ios-close-empty"></i>
        </a>

        {/*=======  search form  =======*/}

        <div class="search-form">
          <form
            action="#"
            onSubmit={(evt) => {
              evt.preventDefault();
              document.querySelector("#search-overlay").style.display = "none";
              history.push(`/search?q=${query}`);
            }}
          >
            <input
              type="search"
              onChange={(evt) => {
                setQuery(evt.target.value);
              }}
              placeholder="Search entire store here ..."
            />
            <button type="submit">
              <i class="ion-android-search"></i>
            </button>
          </form>
        </div>

        {/*=======  End of search form  =======*/}
      </div>

      {/*====================  End of search overlay  ====================*/}
      {/* scroll to top  */}
      <div id="scroll-top">
        <span>Back to top</span>
        <i class="ion-chevron-right"></i>
        <i class="ion-chevron-right"></i>
      </div>
      {/* end of scroll to top */}

      {/*====================  End of header area  ====================*/}
      {/*main-search start */}
      {/* {AddLibrary("/assets/js/vendors.js")}
      {AddLibrary("/assets/js/active.js")} */}

      {/* {AddLibrary("/assets/js/popper.min.js")}
      {AddLibrary("/assets/js/bootstrap.min.js")}
      {AddLibrary("/assets/js/plugins.js")}
      {AddLibrary("/assets/js/ajax-mail.js")}
      {AddLibrary("/assets/js/main.js")} */}
    </>
  );
}

export default HomepageHeader;
