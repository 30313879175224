import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Header from "../layouts/Header";
import HelmetMetaData from "../components/HelmetMetaData";

const TermsConditions = () => {
  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetMetaData
        title={"Terms & Conditions | Ferra Indica"}
        description={"Terms & Conditions | Ferra Indica"}
      />
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title={"Terms & Conditions"} page={"Terms & Conditions"} />
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div class="page-content-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="page-wrapper">
                <div class="page-content-wrapper">
                  <div class="row">
                    <div class="col-lg-12 order-1 order-lg-2">
                      {/*=======  blog page content  =======*/}
                      <div class="blog-page-content">
                        {/*=======  blog post container  =======*/}

                        <div class="blog-single-post-container">
                          {/*=======  post title  =======*/}

                          <h3 class="post-title"> Terms &amp; Conditions </h3>

                          {/*=======  End of Post media  =======*/}

                          {/*=======  Post content  =======*/}

                          <div class="post-content">
                            {/* <h4>DISCLAIMER</h4> */}
                            <ol className="footer-navigation">
                              <li>
                                The information contained in this website is for
                                general information purposes only. The
                                information is provided by www.ferraindica.com
                                and while we endeavor to keep the information up
                                to date and correct, we make no representations
                                or warranties of any kind, express or implied,
                                about the completeness, accuracy, reliability,
                                suitability or availability with respect to the
                                website or the information, products, services,
                                or related graphics contained on the website for
                                any purpose. Any reliance you place on such
                                information is therefore strictly at your own
                                risk
                              </li>
                              <li>
                                In no event will we be liable for any loss or
                                damage including without limitation, indirect or
                                consequential loss or damage, or any loss or
                                damage whatsoever arising from loss of data or
                                profits arising out of, or in connection with,
                                the use of this website
                              </li>
                              <li>
                                Through this website you are able to link to
                                other websites which are not under the control
                                of www.ferraindica.com. We have no control over
                                the nature, content and availability of those
                                sites. The inclusion of any links does not
                                necessarily imply a recommendation or endorse
                                the views expressed within them
                              </li>
                              <li>
                                Every effort is made to keep the website up and
                                running smoothly. However, we cannot take
                                responsibility for, and will not be liable for,
                                the website being temporarily unavailable due to
                                technical issues beyond our control
                              </li>
                              <li>
                                We get in touch with customers for order-related
                                conversations only. We or our representatives
                                will not seek any personal, confidential
                                information from you through calls, e-mails or
                                messages for any purpose.
                              </li>
                            </ol>
                          </div>

                          {/*=====  End of Share post area  ======*/}

                          {/*=======  related post  =======*/}

                          {/*=======  End of related post  =======*/}
                        </div>
                      </div>
                      {/*=======  End of blog page content  =======*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default TermsConditions;
