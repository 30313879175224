import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../config";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomerContext } from "../Routes";

import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Loader from "../components/Loader";
import Header from "../layouts/Header";

const OtpVerification = () => {
  const history = useHistory();
  const scrollRef = useRef(null);

  const [loginLoading, setLoginLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const { state, dispatch } = useContext(CustomerContext);
  // Create State
  const [otp, setOtp] = useState("");

  // verifyOtpHandler
  const verifyOtpHandler = async (evt) => {
    evt.preventDefault();

    const { otp: sendOtp, mobile } =
      JSON.parse(localStorage.getItem("otpVerification")) || {};

    if (otp == Number(sendOtp) / 2) {
      try {
        const verifyAccountResponse = await fetch(
          `${SERVER_URL}/customers/verify`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ mobile }),
          }
        );
        const verifyAccount = await verifyAccountResponse.json();

        // Set data to localstorage
        dispatch({ type: "CUSTOMER", payload: verifyAccount.body.token });
        localStorage.setItem(
          "customerInfo",
          JSON.stringify({
            ...state,
            jwtToken: verifyAccount.body.token,
          })
        );

        // Remove otpVerification data from localstorage
        localStorage.removeItem("otpVerification");

        toast.success(verifyAccount.message);

        history.push("/account/myAccount");
      } catch (error) {
        toast.error(error);
        console.log(error);
      }
    } else {
      toast.error("You Entered wrong OTP !");
    }
  };

  // resendOtpHandler
  const resendOtpHandler = (evt) => {
    evt.preventDefault();
    setResendLoading(true);

    const { mobile } =
      JSON.parse(localStorage.getItem("otpVerification")) || {};

    if (!mobile) {
      toast.warning("Please Register First !");
      history.push("/account/login");
      return;
    }

    const customerData = {
      mobile,
    };

    fetch(SERVER_URL + "/customers/findAccount", {
      method: "POST",
      body: JSON.stringify(customerData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setResendLoading(false);
          if (result.status === 200) {
            toast.success("OTP Send on Your Mobile !");

            localStorage.setItem(
              "otpVerification",
              JSON.stringify({
                mobile: result.body.mobile,
                otp: Number(result.body.otp) * 2,
              })
            );
          } else {
            const keys = Object.keys(result.errors);
            keys.forEach((key) => {
              toast.error(result.errors[key]);
            });
            toast.error(result.message);
          }
        },
        (error) => {
          setResendLoading(false);
          toast.error(error);
        }
      );
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, []);

  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title="OTP Verification" page="enter-otp" />
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area" ref={scrollRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-6 m-auto">
                      {/* Forget Password*/}
                      <form action="#" onSubmit={verifyOtpHandler}>
                        <div className="login-form">
                          <h4 className="login-title">VERIFY OTP !</h4>

                          <div className="row">
                            <div className="col-md-12 col-12">
                              <label>Enter OTP *</label>
                              <input
                                type="text"
                                value={otp}
                                onChange={(evt) => setOtp(evt.target.value)}
                                className="form-control"
                                placeholder={"1234"}
                              />
                            </div>

                            <div className="col-sm-6"></div>

                            <div className="col-sm-6 text-start text-sm-end">
                              <Link
                                to="/account/login"
                                className="forget-pass-link"
                              >
                                {" "}
                                Back to Registration?
                              </Link>
                            </div>

                            <div className="col-md-12">
                              <button
                                className="register-button"
                                disabled={loginLoading ? true : false}
                              >
                                {loginLoading ? <Loader /> : "Verify OTP"}
                              </button>

                              <button
                                onClick={resendOtpHandler}
                                type="button"
                                style={{ marginLeft: "10px" }}
                                className="register-button"
                                disabled={resendLoading ? true : false}
                              >
                                {resendLoading ? <Loader /> : "Resend OTP"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default OtpVerification;
