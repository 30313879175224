import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../config";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CustomerContext } from "../Routes";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";

import { gapi } from "gapi-script";
import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Loader from "../components/Loader";
import Header from "../layouts/Header";
import HelmetMetaData from "../components/HelmetMetaData";
const clientId =
  "1041146774583-eogmiftqgbekc7r62uocdrm5gk7uumco.apps.googleusercontent.com";

const PrivacyPolicy = () => {
  const history = useHistory();

  const [loginData, setLoginData] = useState({
    email: "bikashsinghak47@gmail.com",
    password: "123456",
  });

  const [regData, setRegData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    cPassword: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [regLoading, setRegLoading] = useState(false);
  const { state, dispatch } = useContext(CustomerContext);

  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetMetaData
        title={"Privacy & Policy | Ferra Indica"}
        description={"Privacy & Policy | Ferra Indica"}
      />
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title="Privay Policy" page={"Privay Policy"} />
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div class="page-content-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="page-wrapper">
                <div class="page-content-wrapper">
                  <div class="row">
                    <div class="col-lg-12 order-1 order-lg-2">
                      {/*=======  blog page content  =======*/}
                      <div class="blog-page-content">
                        {/*=======  blog post container  =======*/}

                        <div class="blog-single-post-container">
                          {/*=======  post title  =======*/}

                          <h3 class="post-title">Privay Policy</h3>

                          {/*=======  End of Post media  =======*/}

                          {/*=======  Post content  =======*/}

                          <div class="post-content">
                            <ol className="footer-navigation">
                              <li className="">
                                This privacy policy sets out how{" "}
                                <Link to={"/"}>
                                  {" "}
                                  <b>www.ferraindica.com</b>{" "}
                                </Link>{" "}
                                uses and protects any information that you give{" "}
                                <Link to={"/"}>
                                  {" "}
                                  <b>www.ferraindica.com</b>{" "}
                                </Link>{" "}
                                when you use this website
                              </li>
                              <li>
                                We are committed to ensuring that your privacy
                                is protected. Should we ask you to provide
                                certain information by which you can be
                                identified when using this website, then you can
                                be assured that it will only be used in
                                accordance with this privacy statement
                              </li>
                              <li>
                                We may change this policy from time to time by
                                updating this page. You should check this page
                                from time to time to ensure that you are happy
                                with any changes. This policy is effective from
                                05-09-2022
                              </li>
                            </ol>

                            <h4>We may collect the following information:</h4>
                            <ol className="footer-navigation">
                              <li>Name and Date of birth</li>
                              <li>
                                Contact information including email address
                              </li>
                              <li>
                                Demographic information such as postcode,
                                preferences and interests
                              </li>
                              <li>
                                Other information relevant to customer surveys
                                and/or offers
                              </li>
                            </ol>

                            <h4>
                              We require this information to understand your
                              needs and provide you with a better service, and
                              in particular for the following reasons:
                            </h4>
                            <ol className="footer-navigation">
                              <li>Internal record keeping</li>
                              <li>
                                We may use the information to improve our
                                products and services
                              </li>
                              <li>
                                We may periodically send promotional emails
                                about new products, special offers or other
                                information which we think you may find
                                interesting using the email address which you
                                have provided
                              </li>
                              <li>
                                From time to time, we may also use your
                                information to contact you for market research
                                purposes. We may contact you by email, phone,
                                fax or mail. We may use the information to
                                customize the website according to your
                                interests.
                              </li>
                            </ol>

                            <h4>Security:</h4>
                            <p>
                              We are committed to ensuring that your information
                              is secure. In order to prevent unauthorized access
                              or disclosure we have put in place suitable
                              physical, electronic and managerial procedures to
                              safeguard and secure the information we collect
                              online.
                            </p>

                            <h4>How we use cookies:</h4>
                            <ol className="footer-navigation">
                              <li>
                                A cookie is a small file which asks permission
                                to be placed on your computer&#39;s hard drive.
                                Once you agree, the file is added and the cookie
                                helps analyze web traffic or lets you know when
                                you visit a particular site
                              </li>
                              <li>
                                Cookies allow web applications to respond to you
                                as an individual. The web application can tailor
                                its operations to your needs, likes and dislikes
                                by gathering and remembering information about
                                your preferences
                              </li>
                              <li>
                                We use traffic log cookies to identify which
                                pages are being used. This helps us analyze data
                                about web page traffic and improve our website
                                in order to tailor it to customer needs. We only
                                use this information for statistical analysis
                                purposes and then the data is removed from the
                                system
                              </li>
                              <li>
                                Overall, cookies help us provide you with a
                                better website, by enabling us to monitor which
                                pages you find useful and which you do not. A
                                cookie in no way gives us access to your
                                computer or any information about you, other
                                than the data you choose to share with us
                              </li>
                              <li>
                                You can choose to accept or decline cookies.
                                Most web browsers automatically accept cookies,
                                but you can usually modify your browser setting
                                to decline cookies if you prefer. This may
                                prevent you from taking full advantage of the
                                website
                              </li>
                            </ol>

                            <h4>Links to other websites:</h4>
                            <p>
                              Our website may contain links to other websites of
                              interest. However, once you have used these links
                              to leave our site, you should note that we do not
                              have any control over that other website.
                              Therefore, we cannot be responsible for the
                              protection and privacy of any information which
                              you provide whilst visiting such sites and such
                              sites are not governed by this privacy statement.
                              You should exercise caution and look at the
                              privacy statement applicable to the website in
                              question.
                            </p>

                            <h4>Controlling your personal information:</h4>
                            <p>
                              You may choose to restrict the collection or use
                              of your personal information in the following
                              ways:
                            </p>
                            <ol className="footer-navigation">
                              <li>
                                whenever you are asked to fill in a form on the
                                website, look for the box that you can click to
                                indicate that you do not want the information to
                                be used by anybody for direct marketing purposes
                              </li>
                              <li>
                                if you have previously agreed to us using your
                                personal information for direct marketing
                                purposes, you may change your mind at any time
                                by writing to or emailing us at
                                info@ferraindica.com
                              </li>
                              <li>
                                We will not sell, distribute or lease your
                                personal information to third parties unless we
                                have your permission or are required by law to
                                do so. We may use your personal information to
                                send you promotional information about third
                                parties which we think you may find interesting
                                if you tell us that you wish this to happen
                              </li>
                              <li>
                                You may request details of personal information
                                which we hold about you under the Data
                                Protection Act 1998. A small fee will be
                                payable.
                              </li>
                              <li>
                                If you believe that any information we are
                                holding on you is incorrect or incomplete,
                                please write to us or email us as soon as
                                possible. We will promptly correct any
                                information found to be incorrect
                              </li>
                            </ol>

                            <h4>Contact Us:</h4>
                            <p>
                              If there are any questions regarding this privacy
                              policy you may contact us using the information
                              below:
                            </p>
                            <p>
                              <b>Address:</b> 972, Tagore Park, Kolkata- 700039
                            </p>
                            <p>
                              <b>Email Address:</b> info@ferraindica.com
                            </p>
                          </div>

                          {/*=====  End of Share post area  ======*/}

                          {/*=======  related post  =======*/}

                          {/*=======  End of related post  =======*/}
                        </div>
                      </div>
                      {/*=======  End of blog page content  =======*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default PrivacyPolicy;
