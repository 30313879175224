import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Header from "../layouts/Header";
import HelmetMetaData from "../components/HelmetMetaData";

const ReturnRefundPolicies = () => {
  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetMetaData
        title={"Return & Refund Policies | Ferra Indica"}
        description={"Return & Refund Policies | Ferra Indica"}
      />
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb
        title={"Return & Refund Policies"}
        page={"Return & Refund Policies"}
      />
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div class="page-content-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="page-wrapper">
                <div class="page-content-wrapper">
                  <div class="row">
                    <div class="col-lg-12 order-1 order-lg-2">
                      {/*=======  blog page content  =======*/}
                      <div class="blog-page-content">
                        {/*=======  blog post container  =======*/}

                        <div class="blog-single-post-container">
                          {/*=======  post title  =======*/}

                          <h3 class="post-title">
                            {" "}
                            Return &amp; Refund Policies{" "}
                          </h3>

                          {/*=======  End of Post media  =======*/}

                          {/*=======  Post content  =======*/}

                          <div class="post-content">
                            {/* <h4>Returns &amp; Refund</h4> */}

                            <ol className="footer-navigation">
                              <li className="">
                                We pack our products with a lot of care and love
                                so that it reaches our customers safely. In the
                                unlikely event that your merchandise arrives
                                damaged, please send us a photo of the damaged
                                product and box within 24 hours of receiving
                                your order. We will resolve this immediately for
                                you.
                              </li>
                              <li>
                                As lovely as our products maybe, we understand
                                that sometimes they do not fit in your home as
                                expected. For such instances, we do provide
                                returns as a service. We accept returns only up
                                to 7 days post-delivery of the order. We would
                                request you to clearly mention the items you
                                wish to return.{" "}
                                <em>
                                  In case you are partially returning an order,
                                  please hand over only the partial order to the
                                  pickup agent.
                                </em>
                                <ol className="footer-navigation mt-3" type="a">
                                  <li>
                                    If it is the first time you are returning a
                                    product with us, the return will be
                                    processed without any charge. We will pick
                                    up the product(s) from your doorstep and
                                    once it is picked up from your doorstep, we
                                    will issue a store credit for the value of
                                    items returned so that you can shop for
                                    anything else on our website. Shipping
                                    charges (if any) paid at the time of placing
                                    the orders are not refundable. We reserve
                                    the right to deny a free return if a
                                    previous COD order has been canceled at
                                    doorstep.
                                  </li>
                                  <li>
                                    If you have already returned a product with
                                    us previously, we charge a nominal fee to
                                    process subsequent returns. The fee
                                    structure is as below. Once the items have
                                    been picked up from your doorstep, we will
                                    issue a store credit for the value of items
                                    returned (minus the below charges) so that
                                    you can shop for anything else on our
                                    website. Shipping charges paid at the time
                                    of placing the order are not refundable.
                                  </li>
                                </ol>
                                <ol className="mt-2 footer-navigation" type="i">
                                  <li>
                                    For orders below Rs 2500 -&gt; pickup charge
                                    is Rs 250
                                  </li>
                                  <li>
                                    For orders above Rs 2500 -&gt; pickup charge
                                    is 10% of the order total
                                  </li>
                                </ol>
                              </li>
                              <li>
                                We would require the returned items to have
                                their original packing. The merchandise must be
                                unused and in the same condition that you
                                received it. We would also require all original
                                papers to be returned with the product.
                              </li>
                              <li>
                                In rare cases, it is possible that the pin code
                                might be unserviceable by our courier partners
                                (Delhivery and Amazon Shipping) for returns .In
                                such cases, the reverse pickup can only be done
                                once the pin code becomes serviceable again.
                              </li>
                              <li>
                                We ship several thousand parcels a day and a
                                missing products or theft in transit is
                                extremely rare. In case you feel your parcel has
                                been tampered with, please reach out to our team
                                on Email/WhatsApp as soon as possible and take a
                                video before opening your box. In case you
                                receive an SMS stating the order has been
                                delivered and you have in fact not received the
                                parcel, please reach out to the team within 24
                                hours of receiving the successful delivery
                                email/SMS.
                              </li>
                              <li>
                                Ferra Indica uses 3rd party courier services and
                                is not liable for delays in shipping. We share
                                approximate number of days required for
                                delivery. We also extend the best support
                                possible to ensure that the products reach
                                customers as soon as possible. For any
                                time-sensitive orders, please reach out to
                                customer team to find out more.
                              </li>
                              <li>
                                Orders cannot be cancelled/altered after 4 hours
                                of placing the order. Once an order is
                                packed/shipped, it cannot be cancelled and will
                                be treated as return if there is non-acceptance.
                                For COD orders, Ferra Indica reserves the right
                                to deny future orders. In case of prepaid
                                orders, we will reserve the right to charge 2
                                way shipping costs.
                              </li>
                              <li>
                                We aim to process all returns within 10 days. If
                                you have any questions about your return, feel
                                free to reach out to the our Care team at
                                info@ferraindica.com or on WhatsApp/Call at +91
                                9007330005
                              </li>
                              <li>
                                Return requests have to be approved before
                                processing. Our care team must receive and
                                approve your return request by email or text or
                                WhatsApp. Once your request is received, it may
                                take 48 hours for the team to get back with or
                                without an approval.
                              </li>
                              <li>
                                Once returns are received by us and clear our
                                quality checks, you will receive store
                                credit/voucher which you will be able to redeem
                                in your next purchase. The voucher can be use
                                anytime in the next 3 months without any
                                limitations.
                              </li>
                              <li>
                                We aim to process all disputes very amicably.
                                This is because we want the best for you home.
                                In case there are any disputes which are not
                                resolved despite out best efforts, the
                                jurisdiction for the disputes shall be Kolkata,
                                India.
                              </li>
                              <li>
                                Our products are artisanal and hand-made; there
                                might be slight differences in texture, color
                                and size. These natural aberrations are not
                                considered a defect. We would urge our lovely
                                customers to consider that for such products,
                                the artisans are making each piece individually
                                - the variations add uniqueness to each product.
                              </li>
                              <li>
                                All returns and quality checks are subject to
                                the discretion of Ferra Indica but rest assured,
                                we will always do right by you.
                                <p>
                                  For any other questions or clarifications,
                                  please reach out to our Care team at
                                  info@ferraindica.com.
                                </p>
                                <p>You may also Call/WhatsApp: 9007330005</p>
                              </li>
                            </ol>
                          </div>

                          {/*=====  End of Share post area  ======*/}

                          {/*=======  related post  =======*/}

                          {/*=======  End of related post  =======*/}
                        </div>
                      </div>
                      {/*=======  End of blog page content  =======*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default ReturnRefundPolicies;
