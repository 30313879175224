import React, { useContext, useState, useEffect, useRef } from "react";
import { CustomerContext } from "../Routes";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { SERVER_URL } from "../config";
import Loader from "../components/Loader";
import Header from "../layouts/Header";
import NewsLater from "../components/NewsLater";

// Load Script tag
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Checkout = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(CustomerContext);
  const { cart } = state;
  const productRef = useRef(null);
  const scrollRef = useRef(null);

  const [subtotal, setSubtotal] = useState("");

  const [discountWithCoupon, setDiscountWithCoupon] = useState("");
  const [totalAmount, setTotalAmount] = useState("");

  const [enteredPincode, setEnteredPincode] = useState("");
  const [pincodeValidating, setPincodeValidating] = useState(false);

  const [validPincode, setValidPincode] = useState("");
  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
  const [coupons, setCoupons] = useState([]);
  const [enteredCoupon, setEnteredCoupon] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [couponMessage, setCouponMessage] = useState({
    message: "",
    error: false,
  });
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [specialDiscount, setSpecialDiscount] = useState(0);

  const [appliedCoupon, setAppliedCoupon] = useState({
    code: "",
    discount: "",
    discountType: "",
  });

  const [loaded, setLoaded] = useState(true);

  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    email: "",
    address: "",
    landmark: "",
    city: "",
    pincode: JSON.parse(localStorage.getItem("pincode")) || "",
    mobile: "",
    addressType: "",
  });
  const [availableShipAddress, setAvailableShipAddress] = useState([]);
  const [selectedShipAddress, setSeletedShipAddress] = useState(null);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("ONLINE");
  const [showShippingForm, setShowShippingForm] = useState(false);

  const [shippingCost, setShippingCost] = useState(0);
  const [setting, setSetting] = useState({
    deliveryCharge: [],
    minimumOrderAmount: 1,
  });

  // get my orders
  useEffect(() => {
    if (customerInfo.jwtToken) {
      fetch(`${SERVER_URL}/orders/myOrders`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.status == 200) {
              if (!result?.body?.length) {
                setIsNewCustomer(true);
              }
            } else {
              console.log(result.body);
            }
          },
          (error) => {
            toast.error(error);
            console.log(error);
          }
        );
    }
  }, []);

  useEffect(() => {
    if (!customerInfo || (customerInfo && !customerInfo.jwtToken)) {
      history.push("/account/login");
    }

    // Scroll page on load event
    scrollRef.current.scrollIntoView();

    // Add facebook pixel
    window.fbq("track", "InitiateCheckout");
  }, []);

  // Get Profile
  useEffect(() => {
    fetch(`${SERVER_URL}/customers/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          (customerInfo && customerInfo.jwtToken) || ""
        }`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          //   console.log(result);
          if (result.status == 200) {
            setUserProfile(result.body);
            setAvailableShipAddress(result.body.shippingAddresses || []);

            if (!result.body.shippingAddresses.length) {
              setShowShippingForm(true);
            }
            // result.body.shippingAddresses.map((item) => {
            //   if (item.defaultAddress) {
            //     setSeletedShipAddress(item);
            //   }
            // });
          } else {
            console.log(result);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  // Add Shipping Address
  const orderPlaceHandler = async () => {
    setLoaded(false);

    if (!selectedPaymentMethod) {
      toast.error("Must Select Payment Method!");
      setLoaded(true);
      return;
    }

    // Checking Stocks
    const checkStocks = async (id) => {
      try {
        const response = await fetch(`${SERVER_URL}/products/${id}`);
        const data = await response.json();
        return data;
      } catch (error) {
        toast.error(error.message);
      }
    };

    let outOfStock = [];
    let productInfo = [];
    for (let i = 0; i < cart.length; i++) {
      const stock = await checkStocks(cart[i].productId);
      if (cart[i].quantity > stock.body.stock) {
        outOfStock.push({
          productId: stock.body._id,
          name: stock.body.name,
          stock: stock.body.stock,
        });
      }

      // it helps to update the stocks
      productInfo.push(stock.body);
    }

    if (outOfStock.length) {
      outOfStock.map((item) => {
        toast.warning(`Out of Stock ${item.name}, Stock:  ${item.stock}`);
      });
      productRef.current.scrollIntoView();
      setLoaded(true);
      return;
    }

    if (subtotal < setting.minimumOrderAmount) {
      toast.error(
        `Order Amount is must greater than ${setting.minimumOrderAmount}`
      );
      setLoaded(true);
      return;
    }

    if (!JSON.parse(localStorage.getItem("pincode"))) {
      const validatePincode = async () => {
        try {
          const response = await fetch(
            `${SERVER_URL}/pincodes?pincode=${shippingAddress.pincode}`,
            {
              method: "GET", // or 'PUT'
              headers: {
                "Content-Type": "application/json",
                Authorization: "",
              },
            }
          );
          const data = await response.json();
          return data;
        } catch (error) {
          toast.error("Somthing went wrong to validate the pincode");
          setLoaded(true);
        }
      };

      const pincodeResponse = await validatePincode();

      if (pincodeResponse.status == 200) {
        if (pincodeResponse.body.length) {
          toast.error(
            `${shippingAddress.pincode} Pincode is not Available for Delivery`
          );
          setLoaded(true);
          return;
        }
      } else {
        const errors = Object.keys(pincodeResponse.errors);
        for (let i = 0; i < errors.length; i++) {
          toast.error(pincodeResponse.errors[errors[i]]);
        }
        toast.error(pincodeResponse.message);
        setLoaded(true);
        return;
      }
    }

    // Check Address

    if (!selectedShipAddress && !showShippingForm) {
      toast.error("Must Select Shipping Address!");
      setLoaded(true);
      return;
    } else if (selectedShipAddress && !showShippingForm) {
      if (selectedPaymentMethod == "ONLINE") {
        makePayment();
      } else {
        createOrderHandler(selectedShipAddress);
      }
    } else {
      const addData = { ...shippingAddress };

      fetch(`${SERVER_URL}/customers/address`, {
        method: "POST",
        body: JSON.stringify(addData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.status == 200) {
              toast.success(result.message);
              if (selectedPaymentMethod == "ONLINE") {
                makePayment();
              } else {
                createOrderHandler(shippingAddress);
              }
            } else {
              setLoaded(true);
              const errors = result.errors;
              const keys = Object.keys(errors);
              for (let k of keys) {
                toast.error(errors[k]);
              }
              toast.error(result.message);
            }
          },
          (error) => {
            toast.error(error);
            console.log(error);
            setLoaded(true);
          }
        );
    }
  };

  // Create Order
  const createOrderHandler = async (
    addressForOrder,
    razorpayOrderId,
    razorpayPaymentId
  ) => {
    let orderData = {
      shippingAddress,
      paymentMethod: selectedPaymentMethod,
      razorpayOrderId: razorpayOrderId,
      razorpayPaymentId: razorpayPaymentId,
      subtotal: subtotal,
      totalAmount: totalAmount,
      discountWithCoupon: discountWithCoupon || undefined,
      specialDiscount: specialDiscount
        ? {
            message: "For New Customer",
            discount: "10%",
          }
        : undefined,
      specialDiscountAmount: specialDiscount,
      deliveryAmount: shippingCost || 0,
      coupon: appliedCoupon,
      shippingAddress: { ...addressForOrder, _id: undefined },
    };

    orderData.products = cart;

    // Checking Stocks
    const checkStocks = async (id) => {
      try {
        const response = await fetch(`${SERVER_URL}/products/${id}`);
        const data = await response.json();
        return data;
      } catch (error) {
        toast.error(error.message);
      }
    };

    let outOfStock = [];
    let productInfo = [];
    for (let i = 0; i < cart.length; i++) {
      const stock = await checkStocks(cart[i].productId);
      if (cart[i].quantity > stock.body.stock) {
        outOfStock.push({
          productId: stock.body._id,
          name: stock.body.name,
          stock: stock.body.stock,
        });
      }

      // it helps to update the stocks
      productInfo.push(stock.body);
    }

    if (outOfStock.length) {
      outOfStock.map((item) => {
        toast.warning(
          `Only ${item.stock} item are available for ${item.name} Product`
        );
      });
      productRef.current.scrollIntoView();
      return;
    } else {
      // Make Order Function
      const submitOrder = async () => {
        try {
          const response = await fetch(`${SERVER_URL}/orders`, {
            method: "POST",
            body: JSON.stringify(orderData),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${customerInfo.jwtToken}`,
            },
          });

          return await response.json();
        } catch (error) {
          toast.error(error.message);
        }
      };

      // Calling the makeorder function
      const orderResponse = await submitOrder();
      setLoaded(true);
      if (orderResponse.status == 200) {
        toast.success(orderResponse.message);

        // Update The stock function
        const updateStock = async (id, data) => {
          try {
            const response = await fetch(`${SERVER_URL}/products/stock/${id}`, {
              method: "PUT",
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${customerInfo.jwtToken}`,
              },
            });
            const d = await response.json();
            // console.log(d);
            return d;
          } catch (error) {
            toast.error(error.message);
          }
        };

        // Calling the update stock function
        for (let i = 0; i < productInfo.length; i++) {
          const id = productInfo[i]._id;
          const filteredData = cart.find((item) => {
            return item.productId == productInfo[i]._id;
          });

          const stk =
            Number(productInfo[i].stock) - Number(filteredData.quantity);

          await updateStock(id, { stock: stk });
        }

        dispatch({ type: "CLEAR_CART" });
        setTimeout(() => {
          // history.push("/account/myAccount/orders");
          history.push("/thank-you");
        }, 1000);
      } else {
        const keys = Object.keys(orderResponse.errors);
        for (let i = 0; i < keys.length; i++) {
          toast.error(orderResponse.errors[keys[i]]);
        }
        toast.error(orderResponse.message);
      }
    }
  };

  // Calculate amount
  useEffect(() => {
    // Calculate subtotal
    let sub_total = cart
      .map((product) => product.price * product.quantity)
      .reduce((prev, curr) => prev + curr, 0);

    // Global Variable for delivery charge
    let delvryCharge = 0;

    if (selectedPaymentMethod == "COD") {
      if (sub_total < 1000) {
        delvryCharge = 150;
      } else {
        delvryCharge = 75;
      }
    } else {
      if (sub_total < 1000) {
        delvryCharge = 100;
      } else {
        delvryCharge = 0;
      }
    }

    // check if the new customer
    let specialDiscountAmount = 0;
    if (isNewCustomer) {
      specialDiscountAmount = (sub_total * 10) / 100;
      setSpecialDiscount(specialDiscountAmount);
    }

    setShippingCost(delvryCharge);

    if (appliedCoupon.code) {
      let discountAmount = 0;
      if (appliedCoupon.discountType == "AMOUNT") {
        discountAmount = appliedCoupon.discount;
      } else {
        discountAmount = (sub_total * appliedCoupon.discount) / 100;
      }

      setDiscountWithCoupon(discountAmount);

      setTotalAmount(
        sub_total + delvryCharge - discountAmount - specialDiscountAmount
      );
    } else {
      setTotalAmount(sub_total + delvryCharge - specialDiscountAmount);
    }

    setSubtotal(sub_total);
  }, [
    cart,
    setting.deliveryCharge,
    appliedCoupon,
    selectedPaymentMethod,
    isNewCustomer,
  ]);

  useEffect(() => {
    fetch(`${SERVER_URL}/settings`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      // body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setSetting(data.body);
        } else {
          toast.warn(data.message);
        }
      })
      .catch((error) => {
        console.error("Header Error:", error);
        toast.error(error);
      });
  }, []);

  const validatePincodeHandler = (evt) => {
    evt.preventDefault();
    setPincodeValidating(true);
    fetch(`${SERVER_URL}/pincodes?pincode=${enteredPincode}`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      // body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          if (data.body.length) {
            toast.success("Pincode is Validate");
            setValidPincode(data.body[0].pincode);
            localStorage.setItem("pincode", data.body[0].pincode);
          } else {
            toast.error("Pincode not Available");
          }
        } else {
          const errors = Object.keys(data.errors);
          for (let i = 0; i < errors.length; i++) {
            toast.error(data.errors[errors[i]]);
          }
          toast.error(data.message);
        }
        setPincodeValidating(false);
      })
      .catch((error) => {
        console.error("Header Error:", error);
        toast.error(error);
        setPincodeValidating(false);
      });
  };

  // make online payment
  const makePayment = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      toast.error("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // Create Order
    try {
      const createOrderResponse = await fetch(
        SERVER_URL + "/orders/createRazorpayOrder",
        {
          method: "POST",
          body: JSON.stringify({ amount: totalAmount, paymentId: "123456" }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              (customerInfo && customerInfo.jwtToken) || ""
            }`,
          },
        }
      );

      const createdOrder = await createOrderResponse.json();

      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        currency: createdOrder.body.currency,
        amount: createdOrder.body.amount.toString(),
        order_id: createdOrder.body.id,
        name: "Ferra Indica",
        description: "Product Payment",
        image: "/assets/img/logo.png",
        handler: async function (response) {
          const data = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpaySignature: response.razorpay_signature,
            razorpayOrderId: response.razorpay_order_id,
          };

          // Verify Payment
          const verifyPaymentResponse = await fetch(
            SERVER_URL + "/orders/verifyRazorpayPayment",
            {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${
                  (customerInfo && customerInfo.jwtToken) || ""
                }`,
              },
            }
          );

          // verify payment
          const verifiedPayment = await verifyPaymentResponse.json();
          if (verifiedPayment.status == 200) {
            if (selectedShipAddress && !showShippingForm) {
              createOrderHandler(
                selectedShipAddress,
                data.razorpayOrderId,
                data.razorpayPaymentId
              );
            } else {
              createOrderHandler(
                shippingAddress,
                data.razorpayOrderId,
                data.razorpayPaymentId
              );
            }
          } else {
            toast.error(verifiedPayment.message);
          }
        },
        prefill: {
          name: userProfile.firstName || "Ferra Indica",
          email: userProfile.email || "info@ferraindica.com",
          contact: userProfile.mobile,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      toast.error(error);
      // return {
      //   status: false,
      // };
    }
  };

  // Get all coupon
  useEffect(() => {
    fetch(`${SERVER_URL}/coupons?limit=0`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          (customerInfo && customerInfo.jwtToken) || ""
        }`,
      },
      // body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setCoupons(data.body);
        } else {
          toast.warn(data.message);
        }
      })
      .catch((error) => {
        console.error("Header Error:", error);
      });
  }, []);

  const couponHandler = (evt, coupon = enteredCoupon) => {
    evt.preventDefault();

    // Check coupon code is valid or not
    if (!coupons.some((c) => c.code === coupon)) {
      setCouponMessage({
        ...couponMessage,
        message: "Invalid Coupon Code",
        error: true,
      });
      return;
    }

    // Check coupon amount
    const filteredCoupon = coupons.filter((c) => c.code === coupon);

    if (filteredCoupon.length && subtotal < filteredCoupon[0].minimumAmount) {
      setCouponMessage({
        ...couponMessage,
        message: `Amount is Must at least Rs ${filteredCoupon[0].minimumAmount}`,
        error: true,
      });
      return;
    }

    fetch(`${SERVER_URL}/coupons/verify/${coupon}`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          (customerInfo && customerInfo.jwtToken) || ""
        }`,
      },
      // body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setAppliedCoupon({
            code: data.body.code,
            discount: data.body.discount,
            discountType: data.body.discountType,
          });

          toast.success(data.message);
          setCouponMessage({
            ...couponMessage,
            message: data.message,
            error: false,
          });
        } else {
          toast.error(data.message);
          setCouponMessage({
            ...couponMessage,
            message: data.message,
            error: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });

    dispatch({
      type: "ADD_COUPON",
      payload: {
        code: filteredCoupon.code,
        discount: filteredCoupon.discount,
        discountType: filteredCoupon.discountType,
      },
    });
  };

  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area section-space--half">
        <div className="container wide">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  breadcrumb wrpapper  =======*/}
              <div className="breadcrumb-wrapper breadcrumb-bg">
                {/*=======  breadcrumb content  =======*/}
                <div className="breadcrumb-content">
                  <h2 className="breadcrumb-content__title">Checkout</h2>
                  <ul className="breadcrumb-content__page-map">
                    <li ref={scrollRef}>
                      <a href="index.html">Home</a>
                    </li>
                    <li className="active">Checkout</li>
                  </ul>
                </div>
                {/*=======  End of breadcrumb content  =======*/}
              </div>
              {/*=======  End of breadcrumb wrpapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  {/* Checkout Form s*/}

                  <div className="row row-40">
                    <div className="col-lg-7">
                      {/* Payment Method */}
                      <div className="row">
                        <div className="col-md-12">
                          <h4 className="checkout-title">Payment Method</h4>

                          <div className="checkout-payment-method">
                            <div className="single-method">
                              <input
                                type="radio"
                                checked={
                                  selectedPaymentMethod === "COD" ? true : false
                                }
                                id="payment_cash"
                                name="payment-method"
                                value="COD"
                                onChange={(evt) => {
                                  setSelectedPaymentMethod(evt.target.value);
                                }}
                              />
                              <label for="payment_cash">Cash on Delivery</label>
                              {/* <p data-method="cash">
                                Please send a Check to Store name with Store
                                Street, Store Town, Store State, Store Postcode,
                                Store Country.
                              </p> */}
                            </div>

                            <div className="single-method">
                              <input
                                type="radio"
                                id="payment_online"
                                name="payment-method"
                                value="ONLINE"
                                checked={
                                  selectedPaymentMethod === "ONLINE"
                                    ? true
                                    : false
                                }
                                onChange={(evt) => {
                                  setSelectedPaymentMethod(evt.target.value);
                                }}
                              />
                              <label for="payment_online">Online Payment</label>
                              {/* <p data-method="paypal">
                                Please send a Check to Store name with Store
                                Street, Store Town, Store State, Store Postcode,
                                Store Country.
                              </p> */}
                            </div>

                            <div className="single-method">
                              <input type="checkbox" id="accept_terms" />
                              <label for="accept_terms">
                                I’ve read and accept the terms & conditions
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Shipping Address */}
                      <div id="billing-form">
                        <h4 className="checkout-title"> Shipping Address</h4>

                        <div className="row">
                          {/* Available Shipping Address */}
                          <div className="px-3 mb-3">
                            <div className="checkout-payment-method p-4">
                              {availableShipAddress.map((address, index) => {
                                return (
                                  <div key={index} className="single-method">
                                    <input
                                      type="radio"
                                      id={address._id}
                                      name="shippingAddress"
                                      // checked={
                                      //   address.defaultAddress ? true : false
                                      // }
                                      onChange={(evt) => {
                                        setSeletedShipAddress({
                                          ...address,
                                        });
                                        setShowShippingForm(false);
                                      }}
                                      value="check"
                                    />
                                    <label for={address._id}>
                                      <strong>{address.name}</strong>
                                      <br />
                                      Mobile: {address.mobile}
                                      <br />
                                      Email :{address.email}
                                      <br />
                                      {`${address.address}, ${address.city}, ${address.pincode}`}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            {availableShipAddress.length ? (
                              <div className="single-method">
                                <input
                                  type="checkbox"
                                  onChange={(evt) => {
                                    setShowShippingForm(!showShippingForm);
                                  }}
                                  id="shiping_address"
                                />
                                <label for="shiping_address">
                                  Ship to Different Address
                                </label>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {showShippingForm ? (
                          <form action="#" className="checkout-form">
                            <div className="row mt-3">
                              <div className="col-md-6 col-md-12">
                                <label>Your Name*</label>
                                <input
                                  value={shippingAddress.name}
                                  onChange={(evt) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      name: evt.target.value,
                                    });
                                  }}
                                  type="text"
                                  placeholder="Your Name"
                                />
                              </div>

                              <div className="col-md-6 col-12">
                                <label>Email Address</label>
                                <input
                                  value={shippingAddress.email}
                                  onChange={(evt) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      email: evt.target.value,
                                    });
                                  }}
                                  type="email"
                                  placeholder="Email Address"
                                />
                              </div>

                              <div className="col-md-6 col-12">
                                <label>Phone no*</label>
                                <input
                                  value={shippingAddress.mobile}
                                  onChange={(evt) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      mobile: evt.target.value,
                                    });
                                  }}
                                  type="text"
                                  placeholder="Phone number"
                                />
                              </div>

                              <div className="col-12">
                                <label>Address*</label>
                                <input
                                  type="text"
                                  value={shippingAddress.address}
                                  onChange={(evt) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      address: evt.target.value,
                                    });
                                  }}
                                  placeholder="Full Address"
                                />
                              </div>

                              <div className="col-12">
                                <label>Landmark</label>
                                <input
                                  value={shippingAddress.landmark}
                                  onChange={(evt) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      landmark: evt.target.value,
                                    });
                                  }}
                                  type="text"
                                  placeholder="Landmark"
                                />
                              </div>

                              <div className="col-md-6 col-12">
                                <label>Town/City*</label>
                                <input
                                  value={shippingAddress.city}
                                  onChange={(evt) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      city: evt.target.value,
                                    });
                                  }}
                                  type="text"
                                  placeholder="Town/City"
                                />
                              </div>

                              <div className="col-md-6 col-12">
                                <label>Zip Code*</label>
                                <input
                                  value={shippingAddress.pincode}
                                  readOnly={
                                    !JSON.parse(localStorage.getItem("pincode"))
                                      ? false
                                      : true
                                  }
                                  onChange={(evt) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      pincode: evt.target.value,
                                    });
                                  }}
                                  type="text"
                                  placeholder="Zip Code"
                                />
                              </div>

                              <div className="col-md-6 col-12">
                                <label>Address Type *</label>
                                <div key={""} className="single-method d-flex">
                                  <div className="pr-3">
                                    <input
                                      type="radio"
                                      id={"home"}
                                      name="shippingAddress"
                                      checked={
                                        shippingAddress.addressType == "HOME"
                                          ? true
                                          : false
                                      }
                                      onChange={(evt) => {
                                        setShippingAddress({
                                          ...shippingAddress,
                                          addressType: evt.target.value,
                                        });
                                      }}
                                      value="HOME"
                                    />
                                    <label for={"home"}>HOME</label>
                                  </div>

                                  <div className="px-3">
                                    <input
                                      type="radio"
                                      id={"office"}
                                      checked={
                                        shippingAddress.addressType == "OFFICE"
                                          ? true
                                          : false
                                      }
                                      name="shippingAddress"
                                      onChange={(evt) => {
                                        setShippingAddress({
                                          ...shippingAddress,
                                          addressType: evt.target.value,
                                        });
                                      }}
                                      value="OFFICE"
                                    />
                                    <label for={"office"}>OFFICE</label>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="col-md-6 col-12">
                                <label>Default Address</label>
                                <div className="single-method d-flex">
                                  <input
                                    type="checkbox"
                                    id={"defaultAddress"}
                                    checked={
                                      shippingAddress.defaultAddress == true
                                        ? true
                                        : false
                                    }
                                    name="defaultAddress"
                                    onChange={(evt) => {
                                      setShippingAddress({
                                        ...shippingAddress,
                                        defaultAddress:
                                          !shippingAddress.defaultAddress,
                                      });
                                    }}
                                    value={true}
                                  />
                                  <label for={"defaultAddress"}>
                                    DEFAULT ADDRESS
                                  </label>
                                </div>
                              </div> */}
                            </div>
                          </form>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-lg-5">
                      <div className="row">
                        {/* Cart Total */}
                        <div className="col-12">
                          <h4 className="checkout-title">Cart Total</h4>

                          <div className="checkout-cart-total">
                            <h4 ref={productRef}>
                              Product <span>Total</span>
                            </h4>

                            <ul>
                              {cart &&
                                cart.map((item, index) => {
                                  return (
                                    <li key={`list-${index}`}>
                                      {item?.name?.length > 35
                                        ? item.name.slice(0, 33) + ".."
                                        : item.name}{" "}
                                      X {item.quantity}
                                      <span>
                                        <i
                                          className="fa fa-inr"
                                          aria-hidden="true"
                                        ></i>
                                        {item.price}
                                      </span>
                                    </li>
                                  );
                                })}
                            </ul>

                            <p>
                              Sub Total{" "}
                              <span>
                                <i className="fa fa-inr" aria-hidden="true"></i>
                                {subtotal}
                              </span>
                            </p>
                            {appliedCoupon.code ? (
                              <p>
                                Applied Coupon
                                <i className="badge bg-info">
                                  {appliedCoupon.code}
                                </i>
                                <span>
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {discountWithCoupon}
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                            <p>
                              Shipping Fee{" "}
                              <span>
                                <i className="fa fa-inr" aria-hidden="true"></i>
                                {shippingCost}
                              </span>
                            </p>

                            {isNewCustomer && (
                              <p>
                                Special 10% Discount
                                <i style={{ fontSize: "10px" }}>
                                  (For New Customer)
                                </i>
                                <span>
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  {specialDiscount || 0}
                                </span>
                              </p>
                            )}

                            <h4>
                              Grand Total{" "}
                              <span>
                                <i className="fa fa-inr" aria-hidden="true"></i>
                                {totalAmount}
                              </span>
                            </h4>
                          </div>
                        </div>

                        <div className="discount-coupon col-12">
                          <h4>Discount Coupon Code</h4>

                          <div className="row">
                            <div className="col-md-6 col-12">
                              <form action="" className="checkout-form">
                                <input
                                  type="text"
                                  value={enteredCoupon}
                                  onChange={(evt) =>
                                    setEnteredCoupon(evt.target.value)
                                  }
                                  onFocus={() =>
                                    setCouponMessage({
                                      ...couponMessage,
                                      message: "",
                                    })
                                  }
                                  placeholder="Coupon Code"
                                />
                              </form>{" "}
                              {couponMessage.message && (
                                <div
                                  className={`alert ${
                                    couponMessage.error
                                      ? "alert-danger"
                                      : "alert-success"
                                  }`}
                                >
                                  {couponMessage.message}
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 col-12">
                              <button
                                type="button"
                                className="submit-btn"
                                onClick={couponHandler}
                              >
                                Apply Code
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Place your order */}
                        <div>
                          <button
                            type="button"
                            onClick={orderPlaceHandler}
                            className="place-order py-2"
                            disabled={!loaded ? true : false}
                          >
                            {loaded ? "Place order" : <Loader />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default Checkout;
