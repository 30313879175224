import React from "react";

export const CategorySkeletonLoader = ({ height }) => {
  return (
    <div
      className="category-skeleton"
      style={{ height: height ? `${height}px` : "20px" }}
    >
      <div className="s-img"></div>
      <div className="s-line first"></div>
      <div className="s-line second"></div>
    </div>
  );
};
