import React, { useEffect, useState, useContext, useRef } from "react";
import ProductCard from "../components/ProductCard";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { SERVER_URL } from "../config";
import Slider from "react-slick";

import NewsLater from "../components/NewsLater";
import { CustomerContext } from "../Routes";

import "react-multi-carousel/lib/styles.css";

import HomepageHeader from "../layouts/HomepageHeader";

import LatestBlogPostContainer from "../components/homepage/LatestBlogPostContainer";
import FeaturedAreaContainer from "../components/homepage/FeaturedAreaContainer";
import BannerContainer from "../components/homepage/BannerContainer";
import LatestArivalContainer from "../components/homepage/LatestArivalContainer";
import TestimonialContainer from "../components/homepage/TestimonialContainer";
import HeroSliderContainer from "../components/homepage/HeroSliderContainer";
import CategoryGridContainer from "../components/homepage/CategoryGridContainer";
import { AddLibrary } from "../helpers";
import { ourProductsSliderSetting } from "../helpers/slider";
import HelmetMetaData from "../components/HelmetMetaData";
const Home = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(CustomerContext);
  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
  const [parCategories, setParentcategories] = useState([]);
  const [parCatLoading, setParCatLoading] = useState(false);
  const newsLetterRef = useRef(null);
  const [catProducts, setCatProducts] = useState([]);

  const [bannerAfterSlider, setBannerAfterSlider] = useState([]);

  const [bannerLoading, setBannerLoading] = useState([]);
  const [bannerBetweenProduct, setBannerBetweenPost] = useState({});

  const [addWishlistLoading, setAddWishlistLoading] = useState(false);
  const [removeWishlistLoading, setRemoveWishlistLoading] = useState(false);
  const [addedToWishlist, setAddedToWishlist] = useState(false);
  const [myWishlists, setMyWishlists] = useState([]);
  const [removeFromWishlist, setRemoveFromWishlist] = useState(false);
  const [productSliderSetting, setProductSliderSetting] = useState({
    ...ourProductsSliderSetting,
  });
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // Get All Parent Categoies
  useEffect(() => {
    setParCatLoading(true);
    fetch(`${SERVER_URL}/parentCategories`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setParCatLoading(false);
        if (data.status == 200) {
          setParentcategories(data.body || []);
          // console.log("Par Category", data.body);
        } else {
          // toast.warning(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setParCatLoading(false);
        // toast.warning(error);
      });
  }, []);

  // Get All Parent with Sub Categoies
  useEffect(() => {
    fetch(`${SERVER_URL}/parentCategories/allChildCategories`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setParentcategories(data.body || []);
        } else {
          // toast.warning(data.message);
        }
        // console.log("All Child Category:", data);
      })
      .catch((error) => {
        // toast.error(error);
      });
  }, []);

  // Get All Parent Category With Products
  useEffect(() => {
    // setCategoryLoading(true);
    fetch(`${SERVER_URL}/parentCategories?allProducts=true`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // setCategoryLoading(false);
        if (data.status == 200) {
          setCatProducts(data.body || []);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        // setCategoryLoading(false);
        toast.error(error);
      });
  }, []);

  // Get Banners
  useEffect(() => {
    setBannerLoading(true);
    fetch(`${SERVER_URL}/settings`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBannerLoading(false);
        if (data.status == 200) {
          if (data.body.length) {
            let afterSlider = data.body.filter(
              (value) => value.place == "AFTER_SLIDER"
            );

            let betweenProduct = data.body.filter(
              (value) => value.place == "BETWEEN_PRODUCT"
            );
            if (betweenProduct.length) {
              setBannerBetweenPost(betweenProduct[0] || {});
            }

            setBannerAfterSlider(afterSlider.slice(0, 3) || []);
          }
        } else {
          // toast.warning(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setBannerLoading(false);
        // toast.warning(error);
      });
  }, []);

  const addToCartHandler = async (fromWhere, product, quantity) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        name: product.name,
        slug: product.slug,
        price: product.sellingPrice,
        sku: product.sku,
        weight: product.weight,
        length: product["length"],
        width: product.width,
        height: product.height,
        diameter: product.diameter,
        capacity: product.capacity,
        packageContent: product.packageContent,
        pileHeight: product.pileHeight,
        mountingType: product.mountingType,
        brand: product.brand,
        care: product.care,
        color: product.color,
        description: product.description,
        craftType: product.craftType,
        material: product.material,
        shape: product.shape,
        isReturnable: product.isReturnable,
        returnCondition: product.returnCondition,
        parentCategory: product.parentCategory,
        subCategory: product.subCategory,
        childCategory: product.childCategory,
        productId: product._id,
        quantity: quantity || 1,
        image: product.defaultImage,
        maxOrderQuantity: product.maxOrderQuantity,
      },
    });

    // add to database
    const response = await fetch(`${SERVER_URL}/cartProducts`, {
      body: JSON.stringify({ product: product._id }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();

    if (fromWhere == "BUY_NOW") {
      // history.push("/checkout");
    }
  };

  // Get Wishlists
  useEffect(() => {
    // setProductLoading(true);
    fetch(`${SERVER_URL}/wishlists/myWishlist`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setMyWishlists(data.body || []);
          // console.log(data.body);
        } else {
          // toast.warning(data.message);
        }
      })
      .catch((error) => {
        // setProductLoading(false);
        toast.warning(error);
      });
  }, [addedToWishlist, removeFromWishlist]);

  // Add Product to Wishlist
  const addWishlistHandler = (evt, product) => {
    evt.preventDefault();

    // check customer is logedIn or not
    if (state && state.jwtToken) {
      setAddWishlistLoading(true);
      fetch(`${SERVER_URL}/wishlists`, {
        method: "POST", // or 'PUT'
        body: JSON.stringify({ product: product._id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state ? state.jwtToken : ""}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAddWishlistLoading(false);
          if (data.status == 200) {
            toast.success(data.message);
            setAddedToWishlist(!addedToWishlist);
          } else {
            toast.warning(data.message);
          }
          // console.log("Success:", data);
        })
        .catch((error) => {
          setAddWishlistLoading(false);
          toast.error(error);
        });
    } else {
      toast.warning("Please Login First");
      history.push("/account/login");
    }
  };

  // Remove Product to Wishlist
  const removeWishlistHandler = (evt, wishListId) => {
    evt.preventDefault();
    setRemoveWishlistLoading(true);
    fetch(`${SERVER_URL}/wishlists/${wishListId}`, {
      method: "DELETE", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRemoveWishlistLoading(false);
        if (data.status == 200) {
          toast.success(data.message);
          setRemoveFromWishlist(!removeFromWishlist);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setRemoveWishlistLoading(false);
        toast.error(error);
      });
  };

  const scrollToNewsletter = () => {
    console.log(newsLetterRef);
    newsLetterRef?.current?.scrollIntoView();
  };

  return (
    // {/*Main Wrapper Start */}
    <>
      <HelmetMetaData
        title={
          "Shop for Handmade and Contemporary Home Decor Items: Ferra Indica"
        }
        description={
          "Mix and match elegant handcraft designs to reflect your personality and style. Use our rusty living space decor products for a minimal look."
        }
      />
      {/* Homepage Menu Area */}
      <HomepageHeader />

      {/* End Homepage Menu Area */}

      {/*-===  hero slider area  =====*/}
      <HeroSliderContainer />
      {/*-===  End of hero slider area  -====*/}

      {/*-===  category area ===*/}
      <CategoryGridContainer />
      {/*-===  End of category area ===*/}

      {/*-====================  single row slider tab ====================*/}
      <div className="single-row-slider-tab-area section-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*-=======  section title  =======*/}
              <div className="section-title-wrapper text-center section-space--half">
                <h2 className="section-title">Our Products</h2>
                <p className="section-subtitle">
                  Find the right products from here. The products are organised
                  in category wise.
                </p>
              </div>
              {/*-=======  End of section title  =======*/}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/*-=======  tab slider wrapper  =======*/}

              <div className="tab-slider-wrapper">
                {/*-=======  tab product navigation  =======*/}

                <div className="tab-product-navigation">
                  <div
                    className="nav nav-tabs justify-content-center"
                    id="nav-tab2"
                    role="tablist"
                  >
                    {catProducts.map((category, index) => {
                      ++index;
                      return (
                        <a
                          className={`nav-item nav-link ${
                            index == 1 ? "active" : ""
                          }`}
                          id={`product-tab-${index}`}
                          data-bs-toggle="tab"
                          href={`#product-series-${index}`}
                          role="tab"
                          aria-selected="true"
                        >
                          {category.name}
                        </a>
                      );
                    })}
                  </div>
                </div>

                {/*-=======  End of tab product navigation  =======*/}

                {/*-=======  tab product content  =======*/}
                <div className="tab-content">
                  {catProducts.map((category, index) => {
                    let sliderSetting = { ...productSliderSetting };
                    if (category.products.length < 8) {
                      sliderSetting.rows = 1;
                      sliderSetting.infinite = false;
                    }

                    ++index;
                    return (
                      <div
                        className={`tab-pane fade show ${
                          index == 1 ? "active" : ""
                        }`}
                        id={`product-series-${index}`}
                        role="tabpanel"
                        aria-labelledby={`product-tab-${index}`}
                      >
                        {/*-=======  double row slider wrapper  =======*/}
                        <div
                        // className="double-row-slider-wrapper"
                        >
                          <div className="ht-slick-slider">
                            <Slider {...sliderSetting}>
                              {category.products.map((product, pIndex) => {
                                // Discound Presentage
                                const discountPercent = Math.ceil(
                                  100 -
                                    (Number.parseInt(product.sellingPrice) *
                                      100) /
                                      Number.parseInt(product.mrp)
                                );

                                // Calculate avarage reviews
                                let avarageRating = 0;
                                const sumOfRatings = product.reviews.reduce(
                                  (accumulator, current) => {
                                    return accumulator + current.rating;
                                  },
                                  0
                                );
                                avarageRating = (
                                  sumOfRatings / product.reviews.length
                                ).toFixed(1);

                                // Check Item in available in the cart or not
                                let availableInCart = false;

                                if (state && state.cart.length) {
                                  let available = state.cart.some((item) => {
                                    return item.productId == product._id;
                                  });
                                  if (available) availableInCart = true;
                                }

                                // Check Item in available in the wishlist or not
                                let availableInWishlist = false;

                                let available = myWishlists.some((item) => {
                                  return item.product._id == product._id;
                                });
                                if (available) availableInWishlist = true;
                                // if (myWishlists.length) {
                                // }

                                if (product.status) {
                                  return (
                                    <div
                                      className="col-md-3"
                                      key={`p-${pIndex}`}
                                    >
                                      {/*-=======  single grid product  =======*/}
                                      <ProductCard
                                        availableInCart={availableInCart}
                                        avarageRating={avarageRating}
                                        availableInWishlist={
                                          availableInWishlist
                                        }
                                        product={product}
                                        removeWishlistHandler={
                                          removeWishlistHandler
                                        }
                                        category={category}
                                        addToCartHandler={addToCartHandler}
                                        addWishlistHandler={addWishlistHandler}
                                        addWishlistLoading={addWishlistLoading}
                                        myWishlists={myWishlists}
                                        removeWishlistLoading={
                                          removeWishlistLoading
                                        }
                                        discountPercent={discountPercent}
                                      />
                                      {/*-=======  End of single grid product  =======*/}
                                    </div>
                                  );
                                }
                              })}
                            </Slider>
                          </div>
                        </div>
                        {/*-=======  End of double row slider wrapper  =======*/}
                      </div>
                    );
                  })}
                </div>
                {/*-=======  End of tab product content  =======*/}
              </div>

              {/*-=======  End of tab slider wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*-====================  End of single row slider tab  ====================*/}
      {/*-====================  testimonial area ====================*/}
      <TestimonialContainer />
      {/*-====================  End of testimonial area  ====================*/}
      {/*-====================  single row slider ====================*/}
      <LatestArivalContainer />

      {/*-====================  End of single row slider  ====================*/}
      {/*-====================  banner hover area ====================*/}
      <BannerContainer onClickHandler={scrollToNewsletter} />
      {/*-====================  End of banner hover area  ====================*/}
      {/*-====================  feature logo area ====================*/}
      <FeaturedAreaContainer />
      {/*-====================  End of feature logo area  ====================*/}
      {/*-====================  blog post slider area ====================*/}
      <LatestBlogPostContainer />
      {/*-====================  End of blog post slider area  ====================*/}
      {/*-====================  newsletter area ====================*/}
      <div ref={newsLetterRef}>
        <NewsLater />
      </div>
      {/*-====================  End of newsletter area  ====================*/}

      {/*-====================  End of quick view  ====================*/}
      {/*- scroll to top  */}
      <div id="scroll-top">
        <span>Back to top</span>
        <i className="ion-chevron-right"></i>
        <i className="ion-chevron-right"></i>
      </div>
      {/*- end of scroll to top */}
      {/*-=============================================
    =            JS files        =
    =============================================*/}
      {AddLibrary("/assets/js/vendors.js")}
      {AddLibrary("/assets/js/active.js")}
      {/*- Vendor JS */}
      {/* <script src="assets/js/vendors.js"></script> */}

      {/*- Active JS */}
      {/* <script src="assets/js/active.js"></script> */}

      {/*-=====  End of JS files ======*/}

      {/*- Mirrored from htmldemo.net/Ferra India/Ferra India/index-2.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 14 Jul 2022 05:36:22 GMT */}
    </>
  );
};

export default Home;
