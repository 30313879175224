import React from "react";
import { Link } from "react-router-dom";
import date from "date-and-time";

const BlogPostCard = ({ post: item }) => {
  return (
    // <div className="col-md-4">
    <div className="single-slider-post">
      <div className="single-slider-post__image">
        <Link to={`/blog/${item.slug}`}>
          <img
            width="370"
            height="235"
            src={item.defaultImage}
            className="img-fluid"
            alt=""
          />
        </Link>
        <div className="single-slider-post__date-sticker">
          <span className="month">
            {date.format(date.parse(item.createdAt, "DD-MM-YYYY"), "MMM")}
          </span>
          <span className="day">
            {date.format(date.parse(item.createdAt, "DD-MM-YYYY"), "DD")}
          </span>
        </div>
      </div>
      <div className="single-slider-post__content">
        <h3 className="title">
          <Link to={`/blog/${item.slug}`}>
            {item.title.length > 35
              ? item.title.slice(0, 35) + ".."
              : item.title}
          </Link>
        </h3>
        <p className="post-meta">
          by <Link to={`/blog/${item.slug}`}>{item.author.name}</Link>
        </p>
        {/* <p className="short-desc">
                                      It's no secret, engines and transmissions
                                      can be very expensive...
                                    </p> */}
        <Link to={`/blog/${item.slug}`} className="blog-post-link">
          Read More
        </Link>
      </div>
    </div>
    // </div>
  );
};

export default BlogPostCard;
