import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SERVER_URL } from "../config";
import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Loader from "../components/Loader";
import Header from "../layouts/Header";
import ProductSkeletonLoader from "./ProductSkeletonLoader";
import date from "date-and-time";
import HelmetMetaData from "../components/HelmetMetaData";
const Offers = () => {
  const [availableCoupons, setAvailableCoupons] = useState([]);
  const [couponLoading, selCouponsLoading] = useState(true);

  const copyToClipboard = (couponCode) => {
    navigator.clipboard.writeText(couponCode).then(
      function () {
        toast.success("Coupon Copied Successfully");
      },
      function (err) {
        toast.error("Oops Error", err);
      }
    );
  };

  // Get Coupons
  useEffect(() => {
    selCouponsLoading(true);
    fetch(`${SERVER_URL}/coupons?status=valid`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        selCouponsLoading(false);
        if (data.status == 200) {
          setAvailableCoupons(data.body || []);
        } else {
          toast.warning(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        selCouponsLoading(false);
        toast.warning(error);
      });
  }, []);

  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetMetaData
        title={"Our Offers | Ferra Indica"}
        description={"Our Offers | Ferra Indica"}
      />
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title={"Available Coupons"} page={"offers"} />

      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div
                className="page-wrapper"
                // style={{ background: "#f5f5f5" }}
              >
                <div className="page-content-wrapper">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="about-top-content-wrapper">
                        <div className="row row-30">
                          {couponLoading
                            ? [...Array(3)].map((_, $) => {
                                return (
                                  <div className="col-md-4" key={$}>
                                    <ProductSkeletonLoader />
                                  </div>
                                );
                              })
                            : availableCoupons.length
                            ? availableCoupons.map((coupon, index) => {
                                return (
                                  <div className="col-md-4">
                                    <div className="Innder-offer-box">
                                      <h4 className="font-weight-bold">
                                        Available for{" "}
                                        {coupon.applyFor == "EXISTING_USER"
                                          ? "Existing Users"
                                          : coupon.applyFor == "NEW_USER"
                                          ? "New Users"
                                          : "All Users"}
                                      </h4>
                                      <img
                                        src={
                                          coupon.image != "null"
                                            ? coupon.image
                                            : "assets/img/offer3.jpg"
                                        }
                                      />
                                      <p>
                                        Get Upto{" "}
                                        {coupon.discountType == "AMOUNT" ? (
                                          <>
                                            <i className="fa fa-inr"></i>
                                            {coupon.discount}
                                          </>
                                        ) : (
                                          coupon.discount + "%"
                                        )}{" "}
                                        Off on Minimum Order Amount of{" "}
                                        <i className="fa fa-inr"></i>
                                        {coupon.minimumAmount}
                                      </p>
                                      <span className="text-danger font-weight-bold">
                                        Valid till{" "}
                                        {date.format(
                                          new Date(coupon.validity),
                                          "DD MMM YYYY"
                                        )}
                                      </span>
                                      <div>
                                        <div className="couponBtn text-center">
                                          {coupon.code}
                                          <button
                                            onClick={() => {
                                              copyToClipboard(coupon.code);
                                            }}
                                            type="button"
                                            className="copyBtn"
                                            value={coupon.code}
                                          >
                                            <svg
                                              className="m-auto"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="15"
                                              height="15"
                                              viewBox="0 0 15 15"
                                              style={{ marginTop: "6px" }}
                                            >
                                              <g fill="#FFF">
                                                <path d="M10.279 2.838h-8.33c-.809 0-1.469.659-1.469 1.47v8.33c0 .81.658 1.469 1.47 1.469h8.329a1.47 1.47 0 0 0 1.47-1.47V4.308a1.47 1.47 0 0 0-1.47-1.47zm.49 9.8c0 .27-.22.49-.49.49h-8.33a.49.49 0 0 1-.49-.49V4.307c0-.27.22-.49.49-.49h8.331c.27 0 .49.22.49.49v8.33z"></path>
                                                <path d="M12.909.187H3.96a1.49 1.49 0 0 0-1.491 1.491.497.497 0 1 0 .994 0c0-.274.222-.497.497-.497h8.949c.274 0 .497.223.497.497v8.949a.497.497 0 0 1-.497.497.498.498 0 1 0 0 .994 1.49 1.49 0 0 0 1.491-1.491V1.678c0-.823-.668-1.491-1.491-1.491z"></path>
                                              </g>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
    </>
  );
};

export default Offers;
