import React from "react";
import { Helmet } from "react-helmet";

const MyHelmat = ({ title, description, keywords, author }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title || "Ferra Indica"}</title>
      <meta
        name="description"
        content={
          description || "Ferra Indica Blog : This is a blog of Ferra Indica"
        }
      />
      <meta
        name="keywords"
        content={keywords || "ferra indica, ferra indica shopping"}
      />
      <meta name="author" content={author || "Ferra Indica"} />
    </Helmet>
  );
};

export default MyHelmat;
