import React, { useEffect, useContext, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { SERVER_URL } from "../config";
import { CustomerContext } from "../Routes";
import date from "date-and-time";
import Loader from "../components/Loader";
import AccountDetailsTab from "../components/AccountDetailsTab";
import BillingAddressTab from "../components/BillingAddressTab";
import OrdersTab from "../components/OrdersTab";
import NewsLater from "../components/NewsLater";
import Header from "../layouts/Header";
import WishlistsTab from "../components/WishlistsTab";
import ShipingAddressTab from "../components/ShippingAddressTab";
import { useRef } from "react";

const MyAccount = () => {
  const { slug } = useParams();
  const { state, dispatch } = useContext(CustomerContext);
  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
  const history = useHistory();
  const scrollRef = useRef(null);
  const [profile, setProfile] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState([]);

  const [customer, setCustomer] = useState({
    name: "",
    mobile: "",
    address_1: "",
    address_2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    password: "",
  });

  const [password, setPassword] = useState(undefined);
  const [profileLoading, setProfileLoading] = useState(false);
  const [allWishlistLoading, setAllWishlistLoading] = useState(false);
  const [myWishlists, setMyWishlists] = useState([]);
  const [removeFromWishlist, setRemoveFromWishlist] = useState(false);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [removeWishlistLoading, setRemoveWishlistLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);

  const [addressSubmitting, setAddressSubmitting] = useState(false);
  const [deleteAddressLoading, setDeleteAddressLoading] = useState(false);

  const [needAddressLoading, setNeedAddressLoading] = useState(false);
  const [showShippingForm, setShowShippingForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    city: "",
    pincode: "",
    addressType: "",
    defaultAddress: false,
  });

  useEffect(() => {
    if (!customerInfo) {
      history.push("/account/login");
    }

    if (customerInfo && !customerInfo.jwtToken) {
      history.push("/account/login");
    }

    // Scroll on load

    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, []);

  // Get Profile
  useEffect(() => {
    setProfileLoading(true);
    fetch(`${SERVER_URL}/customers/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setProfileLoading(false);
          if (result.status == 200) {
            setCustomer({
              ...result.body,
            });
            setProfile({
              firstName: result.body.firstName,
              lastName: result.body.lastName,
              mobile: result.body.mobile,
              email: result.body.email,
            });
            setBillingAddress(result.body.billingAddress || {});
            setShippingAddress(result.body.shippingAddresses || []);
          } else {
            toast.error(result.message);
            console.log(result);
          }
        },
        (error) => {
          setProfileLoading(false);
          toast.error(error);
          console.log(error);
        }
      );
  }, [needAddressLoading]);

  // Get Orders
  useEffect(() => {
    if (customer._id) {
      setOrderLoading(true);
      fetch(`${SERVER_URL}/orders?customerId=${customer._id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.status == 200) {
              setOrders(result.body);
            } else {
              toast.error(result.message);
              console.log(result);
            }
            setOrderLoading(false);
          },
          (error) => {
            toast.error(error);
            console.log(error);
            setOrderLoading(false);
          }
        );
    }
  }, [customer]);

  // Logout Function
  const logout = (evt) => {
    evt.preventDefault();
    localStorage.removeItem("customerInfo");
    dispatch({ type: "CLEAR" });
    toast.success("Successfully Logout !!");
    history.push("/account/login");
  };

  // Get Wishlists
  useEffect(() => {
    setAllWishlistLoading(true);
    fetch(`${SERVER_URL}/wishlists/myWishlist`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setAllWishlistLoading(false);
          if (result.status == 200) {
            setMyWishlists(result.body || []);
            // console.log(result.body);
          } else {
            toast.error(result.message);
            // console.log(result);
          }
        },
        (error) => {
          setAllWishlistLoading(false);
          toast.error(error);
          // console.log(error);
        }
      );
  }, [removeFromWishlist]);

  // Remove Product to Wishlist
  const removeWishlistHandler = (evt, wishListId) => {
    evt.preventDefault();
    setRemoveWishlistLoading(true);
    fetch(`${SERVER_URL}/wishlists/${wishListId}`, {
      method: "DELETE", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRemoveWishlistLoading(false);
        if (data.status == 200) {
          toast.success(data.message);
          setRemoveFromWishlist(!removeFromWishlist);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setRemoveWishlistLoading(false);
        toast.error(error);
      });
  };

  const updateProfileHandler = (evt) => {
    const updateData = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      password: profile.password || undefined,
      cPassword: profile.cPassword || undefined,
      billingAddress:
        JSON.stringify(billingAddress) == "{}" ? undefined : billingAddress,
    };

    evt.preventDefault();
    setUpdateProfileLoading(true);
    fetch(`${SERVER_URL}/customers/profile`, {
      method: "PUT",
      body: JSON.stringify(updateData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setUpdateProfileLoading(false);

          if (result.status == 200) {
            setProfile({
              firstName: result.body.firstName,
              lastName: result.body.lastName,
              mobile: result.body.mobile,
              email: result.body.email,
              password: "",
              cPassword: "",
            });
            toast.success(result.message);
          } else {
            const errors = result.errors;
            const keys = Object.keys(errors);
            for (let k of keys) {
              toast.error(errors[k]);
            }
            // console.log(result);
          }
        },
        (error) => {
          setUpdateProfileLoading(false);
          toast.error(error);
          console.log(error);
        }
      );
  };

  const addAddressHandler = (evt, data) => {
    evt.preventDefault();
    setAddressSubmitting(true);
    const addData = { ...data };
    addData.companyName = addData.companyName ? addData.companyName : undefined;

    fetch(`${SERVER_URL}/customers/address`, {
      method: "POST",
      body: JSON.stringify(addData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setAddressSubmitting(false);

          if (result.status == 200) {
            toast.success(result.message);
            setNeedAddressLoading(!needAddressLoading);
            setShowShippingForm(false);
            setFormData({ defaultAddress: false });
          } else {
            const errors = result.errors;
            const keys = Object.keys(errors);
            for (let k of keys) {
              toast.error(errors[k]);
            }
            toast.error(result.message);
          }
        },
        (error) => {
          setAddressSubmitting(false);
          toast.error(error);
          console.log(error);
        }
      );
  };

  const updateAddressHandler = (evt, data) => {
    evt.preventDefault();
    setAddressSubmitting(true);
    const upadteData = { ...data };
    const { _id } = upadteData;
    delete upadteData._id;
    fetch(`${SERVER_URL}/customers/address/${_id}`, {
      method: "PUT",
      body: JSON.stringify(upadteData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setAddressSubmitting(false);

          if (result.status == 200) {
            toast.success(result.message);
            setNeedAddressLoading(!needAddressLoading);
            setShowShippingForm(false);
          } else {
            const errors = result.errors;
            const keys = Object.keys(errors);
            for (let k of keys) {
              toast.error(errors[k]);
            }
            toast.error(result.message);
          }
        },
        (error) => {
          setAddressSubmitting(false);
          toast.error(error);
          console.log(error);
        }
      );
  };

  const deleteAddressHandler = (evt, _id) => {
    evt.preventDefault();
    setAddressSubmitting(true);

    fetch(`${SERVER_URL}/customers/address/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setAddressSubmitting(false);

          if (result.status == 200) {
            setNeedAddressLoading(!needAddressLoading);
            toast.success(result.message);
          } else {
            const errors = result.errors;
            const keys = Object.keys(errors);
            for (let k of keys) {
              toast.error(errors[k]);
            }
            toast.error(result.message);
          }
        },
        (error) => {
          setAddressSubmitting(false);
          toast.error(error);
          console.log(error);
        }
      );
  };

  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area section-space--half">
        <div className="container wide">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  breadcrumb wrpapper  =======*/}
              <div className="breadcrumb-wrapper breadcrumb-bg">
                {/*=======  breadcrumb content  =======*/}
                <div className="breadcrumb-content" ref={scrollRef}>
                  <h2 className="breadcrumb-content__title">My Account</h2>
                  <ul className="breadcrumb-content__page-map">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li className="active">My Account</li>
                  </ul>
                </div>
                {/*=======  End of breadcrumb content  =======*/}
              </div>
              {/*=======  End of breadcrumb wrpapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    {/* My Account Tab Menu Start */}
                    <div className="col-lg-3 col-12">
                      <div className="myaccount-tab-menu nav" role="tablist">
                        <Link
                          to="#dashboad"
                          className={!slug ? "active" : ""}
                          data-bs-toggle="tab"
                          onClick={(evt) => {
                            window.history.pushState(
                              null,
                              null,
                              "/account/myAccount/dashboard"
                            );
                          }}
                        >
                          <i className="fa fa-dashboard"></i>
                          Dashboard
                        </Link>

                        <Link
                          className={slug == "orders" ? "active" : ""}
                          to="#orders"
                          data-bs-toggle="tab"
                          onClick={(evt) => {
                            window.history.pushState(
                              null,
                              null,
                              "/account/myAccount/orders"
                            );
                          }}
                        >
                          <i className="fa fa-cart-arrow-down"></i>
                          Orders
                        </Link>

                        <Link
                          className={slug == "wishlists" ? "active" : ""}
                          to="#wishlists"
                          data-bs-toggle="tab"
                          onClick={(evt) => {
                            window.history.pushState(
                              null,
                              null,
                              "/account/myAccount/wishlists"
                            );
                          }}
                        >
                          <i className="fa fa-cart-arrow-down"></i>
                          Wishlists
                        </Link>

                        {/* <Link
                          to="#download"
                          className={slug == "download" ? "active" : ""}
                          data-bs-toggle="tab"
                          onClick={(evt) => {
                            window.history.pushState(
                              null,
                              null,
                              "/account/myAccount/download"
                            );
                          }}
                        >
                          <i className="fa fa-cloud-download"></i>
                          Download
                        </Link> */}

                        <Link
                          to="#payment-method"
                          className={slug == "paymentMethod" ? "active" : ""}
                          data-bs-toggle="tab"
                          onClick={(evt) => {
                            window.history.pushState(
                              null,
                              null,
                              "/account/myAccount/paymentMethod"
                            );
                          }}
                        >
                          <i className="fa fa-credit-card"></i>
                          Payment Method
                        </Link>

                        <Link
                          to="#address-edit"
                          className={slug == "address" ? "active" : ""}
                          data-bs-toggle="tab"
                          onClick={(evt) => {
                            window.history.pushState(
                              null,
                              null,
                              "/account/myAccount/address"
                            );
                          }}
                        >
                          <i className="fa fa-map-marker"></i>
                          address
                        </Link>

                        <a
                          href="#account-info"
                          data-bs-toggle="tab"
                          className={slug == "account" ? "active" : ""}
                          onClick={(evt) => {
                            window.history.pushState(
                              null,
                              null,
                              "/account/myAccount/account"
                            );
                          }}
                        >
                          <i className="fa fa-user"></i> Account Details
                        </a>

                        <a href="#" onClick={logout}>
                          <i className="fa fa-sign-out"></i> Logout
                        </a>
                      </div>
                    </div>
                    {/* My Account Tab Menu End */}

                    {/* My Account Tab Content Start */}
                    <div className="col-lg-9 col-12">
                      <div className="tab-content" id="myaccountContent">
                        {/* Single Tab Content Start */}
                        <div
                          className={
                            !slug
                              ? "tab-pane fade show active"
                              : "tab-pane fade show"
                          }
                          id="dashboad"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            <h3>Dashboard</h3>

                            {profileLoading ? (
                              <Spinner />
                            ) : (
                              <div className="welcome mb-20">
                                <p>
                                  Hello, <strong> {profile.firstName} </strong>
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* Single Tab Content End */}

                        {/* Single Tab Content Start */}
                        <div
                          className={
                            slug == "orders"
                              ? "tab-pane fade active"
                              : "tab-pane fade"
                          }
                          id="orders"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            <OrdersTab data={orders} />
                          </div>
                        </div>
                        {/* Single Tab Content End */}

                        {/* Single Tab Content Start */}
                        <div
                          className={
                            slug == "wishlists"
                              ? "tab-pane fade active"
                              : "tab-pane fade"
                          }
                          id="wishlists"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            <WishlistsTab
                              data={myWishlists}
                              onLoding={removeWishlistLoading}
                              onRemove={removeWishlistHandler}
                            />
                          </div>
                        </div>
                        {/* Single Tab Content End */}

                        {/* Single Tab Content Start */}
                        {/* <div
                          className={
                            slug == "download"
                              ? "tab-pane fade active"
                              : "tab-pane fade"
                          }
                          id="download"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            <h3>Downloads</h3>

                            <div className="myaccount-table table-responsive text-center">
                              <table className="table table-bordered">
                                <thead className="thead-light">
                                  <tr>
                                    <th>Product</th>
                                    <th>Date</th>
                                    <th>Expire</th>
                                    <th>Download</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr>
                                    <td>Mostarizing Oil</td>
                                    <td>Aug 22, 2018</td>
                                    <td>Yes</td>
                                    <td>
                                      <a href="#" className="btn">
                                        Download File
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Katopeno Altuni</td>
                                    <td>Sep 12, 2018</td>
                                    <td>Never</td>
                                    <td>
                                      <a href="#" className="btn">
                                        Download File
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div> */}
                        {/* Single Tab Content End */}

                        {/* Single Tab Content Start */}
                        <div
                          className={
                            slug == "paymentMethod"
                              ? "tab-pane fade active"
                              : "tab-pane fade"
                          }
                          id="payment-method"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            <h3>Payment Method</h3>

                            <p className="saved-message">
                              You Can't Saved Your Payment Method yet.
                            </p>
                          </div>
                        </div>
                        {/* Single Tab Content End */}

                        {/* Single Tab Content Start */}
                        <div
                          className={
                            slug == "address"
                              ? "tab-pane fade active"
                              : "tab-pane fade"
                          }
                          id="address-edit"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            {/* <BillingAddressTab
                              updateProfileHandler={updateProfileHandler}
                              profile={profile}
                              billingAddress={billingAddress}
                              setBillingAddress={setBillingAddress}
                              setProfile={setProfile}
                              updateProfileLoading={updateProfileLoading}
                            /> */}
                            <ShipingAddressTab
                              addAddressHandler={addAddressHandler}
                              updateAddressHandler={updateAddressHandler}
                              shippingAddress={shippingAddress}
                              showShippingForm={showShippingForm}
                              setShowShippingForm={setShowShippingForm}
                              formData={formData}
                              setFormData={setFormData}
                              addressSubmitting={addressSubmitting}
                              deleteAddressHandler={deleteAddressHandler}
                            />
                          </div>
                        </div>
                        {/* Single Tab Content End */}

                        {/* Single Tab Content Start */}
                        <div
                          className={
                            slug == "account"
                              ? "tab-pane fade active"
                              : "tab-pane fade"
                          }
                          id="account-info"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            <AccountDetailsTab
                              updateProfileHandler={updateProfileHandler}
                              profile={profile}
                              setProfile={setProfile}
                              updateProfileLoading={updateProfileLoading}
                            />
                          </div>
                        </div>
                        {/* Single Tab Content End */}
                      </div>
                    </div>
                    {/* My Account Tab Content End */}
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default MyAccount;
