import React from "react";
import { Link } from "react-router-dom";

const OrdersTab = ({ data = [] }) => {
  return (
    <>
      <h3>Orders</h3>

      {data.length ? (
        <div className="myaccount-table table-responsive text-center">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Date</th>
                <th>Status</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={`order-${index}`}>
                    <td> {++index} </td>
                    <td> {item.products[0].name} </td>
                    <td> {item.createdAt} </td>
                    <td> {item.orderStatus} </td>
                    <td>
                      {" "}
                      <i className="fa fa-inr"></i> {item.totalAmount}{" "}
                    </td>
                    <td>
                      <Link
                        to={`/account/orderDetails/${item._id}`}
                        className="btn"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-danger">NO ORDER AVAILABLE</div>
      )}
    </>
  );
};

export default OrdersTab;
