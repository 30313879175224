import React from "react";
import parse from "html-react-parser";
import Loader from "./Loader";
import ProductSkeletonLoader from "./ProductSkeletonLoader";
import { Link } from "react-router-dom";
import date from "date-and-time";
import FacebookSharingButton from "./FacebookSharingButton";
import TwitterSharingButton from "./TwitterSharingButton";
import WhatsappSharingButton from "./WhatsappSharingButton";

const PostDetails = ({
  data,
  relatedPost = [],
  relatedPostLoading = true,
  blogPostLoading = true,
}) => {
  const {
    title = "",
    slug = "",
    defaultImage = "",
    body = "",
    metaDescription = "",
    category = {},
    author = {},
    createdAt = "",
    tags,
  } = data;

  return (
    <div class="blog-page-content">
      {/*=======  blog post container  =======*/}

      {blogPostLoading ? (
        <ProductSkeletonLoader />
      ) : (
        <div class="blog-single-post-container">
          {/*=======  post title  =======*/}

          <h1 class="post-title">{title}</h1>

          {/*=======  End of post title  =======*/}

          {/*=======  Post meta  =======*/}
          <div class="post-meta">
            <p>
              <span>
                <i class="fa fa-user-circle"></i> Posted By:{" "}
              </span>
              <a href="#">{author.name}</a> <span class="separator">|</span>
              <span>
                <i class="fa fa-calendar"></i> Posted On:{" "}
                <a href="#">
                  {date.format(
                    date.parse(createdAt, "DD-MM-YYYY"),
                    "DD-MMM-YYYY"
                  )}
                </a>
              </span>
            </p>
          </div>

          {/*=======  End of Post meta  =======*/}

          {/*=======  Post media  =======*/}

          <div class="single-blog-post-media">
            <div class="image">
              {defaultImage ? (
                <img
                  width="1170"
                  height="743"
                  src={defaultImage}
                  class="img-fluid"
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
          </div>

          {/*=======  End of Post media  =======*/}

          {/*=======  Post content  =======*/}

          <div class="post-content">{parse(body)}</div>

          {/*=======  End of Post content  =======*/}

          {/*=======  Tags area  =======*/}

          <div class="tag-area">
            {/* <span>Tags: </span> */}
            <ul>
              {tags
                ? tags.split(",").map((tag, i, arr) => {
                    return (
                      <li key={`tag-${i}`}>
                        <a href="#">{tag}</a>
                        {!(arr.length - 1 == i) ? "," : ""}
                      </li>
                    );
                  })
                : ""}
            </ul>
          </div>

          {/*=======  End of Tags area  =======*/}

          {/*=======  Share post area  =======*/}

          <div class="social-share-buttons">
            <h3>share this post</h3>
            <ul>
              <li>
                <TwitterSharingButton />
                {/* <a class="twitter" href="#">
                  <i class="fa fa-twitter"></i>
                </a> */}
              </li>
              <li>
                <FacebookSharingButton />
                {/* <a class="facebook" href="#">
                  <i class="fa fa-facebook"></i>
                </a> */}
              </li>
              <li>
                <WhatsappSharingButton />
                {/* <a class="google-plus" href="#">
                  <i class="fa fa-whatsapp"></i>
                </a> */}
              </li>
              {/* <li>
                <a class="pinterest" href="#">
                  <i class="fa fa-pinterest"></i>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      )}
      {/*=====  End of Share post area  ======*/}

      {/*=======  related post  =======*/}

      <div className="blog-single-post-container">
        <div class="related-post-container">
          <div class="row">
            <div class="col-lg-12">
              <h3 class="related-post-title">RELATED POSTS</h3>
            </div>
          </div>
          <div class="row">
            {relatedPostLoading ? (
              [...Array(3)].map((_) => {
                return (
                  <div className="col-md-4">
                    <ProductSkeletonLoader />
                  </div>
                );
              })
            ) : relatedPost.length ? (
              relatedPost.map((relPost, index) => {
                if (relPost.slug != slug) {
                  return (
                    <div key={`relatedPost-${index}`} class="col-lg-4 col-md-4">
                      <div class="single-related-post">
                        <div class="image">
                          <Link to={`/blog/${relPost.slug}`}>
                            {relPost.defaultImage ? (
                              <img
                                width="370"
                                height="235"
                                src={relPost.defaultImage}
                                class="img-fluid"
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </Link>
                        </div>
                        <div class="content">
                          <h3 class="related-post-title">
                            <Link to={`/blog/${relPost.slug}`}>
                              {relPost.title.length > 22
                                ? relPost.title.slice(0, 22) + ".."
                                : relPost.title}
                            </Link>
                            <span>
                              {date.format(
                                date.parse(relPost.createdAt, "DD-MM-YYYY"),
                                "DD-MMM-YYYY"
                              )}
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="alert alert-danger">Post Not Found</div>
            )}
          </div>
        </div>
      </div>
      {/*=======  End of related post  =======*/}

      {/*=======  End of blog post container  =======*/}

      {/*=============================================
                        =            Comment section         =
                        =============================================*/}

      {/*=====  End of Comment section  ======*/}
    </div>
  );
};

export default PostDetails;
