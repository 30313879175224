import React, { useEffect, useContext, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { SERVER_URL } from "../config";
import { CustomerContext } from "../Routes";
import date from "date-and-time";
import Loader from "../components/Loader";
import AccountDetailsTab from "../components/AccountDetailsTab";
import BillingAddressTab from "../components/BillingAddressTab";
import OrdersTab from "../components/OrdersTab";
import NewsLater from "../components/NewsLater";
import Header from "../layouts/Header";
import WishlistsTab from "../components/WishlistsTab";
import ShipingAddressTab from "../components/ShippingAddressTab";

const CancelOrder = () => {
  const { id } = useParams();
  const { state, dispatch } = useContext(CustomerContext);
  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
  const history = useHistory();
  const [profile, setProfile] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [shippingAddress, setShippingAddress] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    products: [],
    adonProducts: [],
  });
  const [isReturnable, setIsReturnable] = useState(false);

  const [customer, setCustomer] = useState({
    name: "",
    mobile: "",
    address_1: "",
    address_2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    password: "",
  });

  const [password, setPassword] = useState(undefined);
  const [profileLoading, setProfileLoading] = useState(false);
  const [allWishlistLoading, setAllWishlistLoading] = useState(false);
  const [myWishlists, setMyWishlists] = useState([]);
  const [removeFromWishlist, setRemoveFromWishlist] = useState(false);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [removeWishlistLoading, setRemoveWishlistLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);

  const [addressSubmitting, setAddressSubmitting] = useState(false);
  const [deleteAddressLoading, setDeleteAddressLoading] = useState(false);

  const [needAddressLoading, setNeedAddressLoading] = useState(false);
  const [showShippingForm, setShowShippingForm] = useState(false);
  const [formData, setFormData] = useState({
    cancelMessage: "",
    orderStatus: "CANCELLED",
  });

  useEffect(() => {
    if (!customerInfo) {
      history.push("/account/login");
    }

    if (customerInfo && !customerInfo.jwtToken) {
      history.push("/account/login");
    }
  }, []);

  // Get Profile
  useEffect(() => {
    setProfileLoading(true);
    fetch(`${SERVER_URL}/customers/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setProfileLoading(false);
          if (result.status == 200) {
            setCustomer({
              ...result.body,
            });
            setProfile({
              firstName: result.body.firstName,
              lastName: result.body.lastName,
              mobile: result.body.mobile,
              email: result.body.email,
            });
            setBillingAddress(result.body.billingAddress || {});
            setShippingAddress(result.body.shippingAddresses || []);
          } else {
            toast.error(result.message);
            console.log(result);
          }
        },
        (error) => {
          setProfileLoading(false);
          toast.error(error);
          console.log(error);
        }
      );
  }, [needAddressLoading]);

  // Get Orders Details
  useEffect(() => {
    setAllWishlistLoading(true);
    fetch(`${SERVER_URL}/orders/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setAllWishlistLoading(false);
          if (result.status == 200) {
            console.log(result.body);
            setOrderDetails(result.body || []);
            // console.log(result.body);
          } else {
            toast.error(result.message);
            // console.log(result);
          }
        },
        (error) => {
          setAllWishlistLoading(false);
          toast.error(error);
          // console.log(error);
        }
      );
  }, [removeFromWishlist]);

  const cancelOrderHandler = (evt) => {
    evt.preventDefault();
    const updateData = {
      cancelledBy: "USER",
      cancelMessage: formData.cancelMessage || undefined,
      orderStatus: formData.orderStatus,
    };
    setUpdateProfileLoading(true);
    fetch(`${SERVER_URL}/orders/cancelOrder/${id}`, {
      method: "PUT",
      body: JSON.stringify(updateData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setUpdateProfileLoading(false);

          if (result.status == 200) {
            toast.success(result.message);
            setTimeout(() => {
              history.goBack();
            }, 1000);
          } else {
            const errors = result.errors;
            const keys = Object.keys(errors);
            for (let k of keys) {
              toast.error(errors[k]);
            }
            toast.error(result.message);
          }
        },
        (error) => {
          setUpdateProfileLoading(false);
          toast.error(error);
          console.log(error);
        }
      );
  };

  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area section-space--half">
        <div className="container wide">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  breadcrumb wrpapper  =======*/}
              <div className="breadcrumb-wrapper breadcrumb-bg">
                {/*=======  breadcrumb content  =======*/}
                <div className="breadcrumb-content">
                  <h2 className="breadcrumb-content__title">My Account</h2>
                  <ul className="breadcrumb-content__page-map">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li className="active">My Account</li>
                  </ul>
                </div>
                {/*=======  End of breadcrumb content  =======*/}
              </div>
              {/*=======  End of breadcrumb wrpapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    {/* My Account Tab Menu Start */}
                    <div className="col-lg-12 m-auto">
                      <div className="row">
                        <div className="col-md-9">
                          <div className="card bg-white">
                            <div className="card-header d-flex justify-content-between bg-white">
                              <h3>Order Details</h3>
                              <h4>
                                Order Amount{" "}
                                <span className="fa fa-inr">
                                  {orderDetails.totalAmount}
                                </span>
                              </h4>
                            </div>
                            <div className="card-body">
                              <div class="container">
                                <div className="row">
                                  {/* Product Details */}
                                  <div className="col-md-12">
                                    <form
                                      action=""
                                      onSubmit={cancelOrderHandler}
                                    >
                                      <div className="form-group">
                                        <label htmlFor="" className="mb-2">
                                          <h4>Write the Cancellation Reason</h4>
                                        </label>
                                        <textarea
                                          name=""
                                          id=""
                                          cols="30"
                                          value={formData.cancelMessage}
                                          rows="10"
                                          onChange={(evt) => {
                                            setFormData({
                                              ...formData,
                                              cancelMessage: evt.target.value,
                                            });
                                          }}
                                          className="form-control"
                                        ></textarea>
                                      </div>
                                      <div className="form-group mt-3">
                                        <button className="btn submit-btn">
                                          Cancel Order
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 ">
                          <div className="card bg-white mb-3">
                            <div className="card-body">
                              <h4>
                                Payment Method : {orderDetails.paymentMethod}
                              </h4>
                            </div>
                          </div>

                          <div className="card bg-white mb-3">
                            <div className="card-body">
                              <h4 className="pb-3">Shipping Address</h4>
                              {orderDetails.shippingAddress ? (
                                <div className="">
                                  <h5 className="pb-2">
                                    Name: {orderDetails.shippingAddress.name}{" "}
                                  </h5>
                                  <h5 className="pb-2">
                                    Email: {orderDetails.shippingAddress.email}{" "}
                                  </h5>
                                  <h5 className="pb-2">
                                    Mobile:{" "}
                                    {orderDetails.shippingAddress.mobile}{" "}
                                  </h5>
                                  <h5 className="pb-2">
                                    Address:{" "}
                                    {orderDetails.shippingAddress.address}{" "}
                                  </h5>
                                  <h5 className="pb-2">
                                    City: {orderDetails.shippingAddress.city}{" "}
                                  </h5>
                                  <h5 className="pb-2">
                                    Pincode:{" "}
                                    {orderDetails.shippingAddress.pincode}{" "}
                                  </h5>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default CancelOrder;
