import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SERVER_URL } from "../config";

import NewsLater from "../components/NewsLater";
import Breadcrumb from "../components/Breadcrumb";
import Header from "../layouts/Header";
import { Link, useParams } from "react-router-dom";
import PostDetails from "../components/PostDetails";
import { CategorySkeletonLoader } from "../components/CategorySkeletonLoader";
import MyHelmat from "../components/MyHelmat";
import date from "date-and-time";

// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = false;
  document.body.appendChild(script);
}

const BlogPost = () => {
  const { slug = null } = useParams();
  const [blogCategories, setBlogCategories] = useState([]);
  const [categoryLoading, setCalerogyLoading] = useState(true);

  const [recentPostLoading, setRecentPostLoading] = useState(true);
  const [recentPosts, setRecentPosts] = useState([]);

  const [blogPostLoading, setBlogPostLoading] = useState(true);
  const [blogPost, setblogPost] = useState({
    author: {},
  });

  const [relatedPostLoading, setRelatedPostLoading] = useState(true);
  const [relatedPost, setRelatedPost] = useState({});

  // Get Blog Categories
  useEffect(() => {
    setCalerogyLoading(true);
    fetch(`${SERVER_URL}/categories?limit=0`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCalerogyLoading(false);
        if (data.status == 200) {
          setBlogCategories(data.body || []);
        } else {
          toast.error(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setCalerogyLoading(false);
        toast.warning(error);
      });
  }, []);

  // Recent Post
  useEffect(() => {
    setRecentPostLoading(true);
    fetch(`${SERVER_URL}/blogPosts?limit=5`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRecentPostLoading(false);
        if (data.status == 200) {
          setRecentPosts(data.body || []);
        } else {
          toast.error(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setRecentPostLoading(false);
        toast.warning(error);
      });
  }, []);

  // Blog Post by Slug
  useEffect(() => {
    setBlogPostLoading(true);
    fetch(`${SERVER_URL}/blogPosts?slug=${slug}`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBlogPostLoading(false);
        if (data.status == 200) {
          setblogPost(data.body[0] || {});
        } else {
          toast.error(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setBlogPostLoading(false);
        toast.warning(error);
      });
  }, [slug]);

  // Get Related Post
  useEffect(() => {
    if (blogPost.category) {
      setRelatedPostLoading(true);
      fetch(
        `${SERVER_URL}/blogPosts?categoryId=${blogPost.category._id}&limit=3`,
        {
          method: "GET", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setRelatedPostLoading(false);
          if (data.status == 200) {
            setRelatedPost(data.body || {});
          } else {
            toast.error(data.message);
          }
          // console.log("Success:", data);
        })
        .catch((error) => {
          setRelatedPostLoading(false);
          toast.warning(error);
        });
    } else {
      setRelatedPostLoading(false);
    }
  }, [blogPost]);

  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  return (
    <>
      <Header />
      <MyHelmat
        title={blogPost.title || ""}
        description={blogPost.metaDescription || ""}
        keywords={blogPost.keywords || ""}
        author={blogPost.author ? blogPost.author.name : ""}
      />
      {/*====================  breadcrumb area ====================*/}
      <Breadcrumb title={"Blog Post"} page={blogPost.slug || ""} />

      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div class="page-content-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="page-wrapper">
                <div class="page-content-wrapper">
                  <div class="row">
                    <div class="col-lg-3 order-2">
                      {/*=======  page sidebar wrapper =======*/}
                      <div class="page-sidebar-wrapper">
                        {/*=======  single sidebar widget  =======*/}
                        <div class="single-sidebar-widget">
                          <h4 class="single-sidebar-widget__title">
                            Categories
                          </h4>
                          <ul class="single-sidebar-widget__category-list">
                            {categoryLoading
                              ? [...Array(5)].map((_, i) => {
                                  return <CategorySkeletonLoader key={i} />;
                                })
                              : blogCategories.map((category, index) => {
                                  return (
                                    <li>
                                      <Link to={`/blog/cat/${category.slug}`}>
                                        {category.name}
                                        <span class="counter">
                                          {category.posts}
                                        </span>
                                      </Link>
                                    </li>
                                  );
                                })}
                          </ul>
                        </div>
                        {/*=======  End of single sidebar widget  =======*/}

                        {/*=======  single sidebar widget  =======*/}
                        <div class="single-sidebar-widget">
                          <h4 class="single-sidebar-widget__title">
                            Recent Posts
                          </h4>

                          {/*=======  block container  =======*/}

                          <div class="block-container">
                            {/*=======  single block  =======*/}

                            {recentPostLoading
                              ? [...Array(5)].map((_, i) => {
                                  return (
                                    <CategorySkeletonLoader
                                      height={80}
                                      key={i}
                                    />
                                  );
                                })
                              : recentPosts.map((recentPost, index) => {
                                  return (
                                    <div class="single-block d-flex">
                                      <div class="image">
                                        <Link to={`/blog/${recentPost.slug}`}>
                                          <img
                                            width="370"
                                            height="235"
                                            src={recentPost.defaultImage}
                                            class="img-fluid"
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                      <div class="content">
                                        <p>
                                          <Link to={`/blog/${recentPost.slug}`}>
                                            {recentPost.title}
                                          </Link>{" "}
                                          <span>
                                            {date.format(
                                              date.parse(
                                                recentPost.createdAt,
                                                "DD-MM-YYYY"
                                              ),
                                              "DD-MMM-YYYY"
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}

                            {/*=======  End of single block  =======*/}

                            {/*=======  End of single block  =======*/}
                          </div>

                          {/*=======  End of block container  =======*/}
                        </div>
                        {/*=======  End of single sidebar widget  =======*/}

                        {/*=======  single sidebar widget  =======*/}

                        {/*=======  End of single sidebar widget  =======*/}

                        {/*=======  single sidebar widget  =======*/}
                        {/* <div class="single-sidebar-widget">
                          <h4 class="single-sidebar-widget__title">
                            Popular Tags
                          </h4>
                          <ul class="single-sidebar-widget__tag-list">
                            <li>
                              <a href="#">Car Seats</a>
                            </li>
                            <li>
                              <a href="#">Chair</a>
                            </li>
                            <li>
                              <a href="#">Engine Parts</a>
                            </li>
                            <li>
                              <a href="#">Sofas</a>
                            </li>
                            <li>
                              <a href="#">Sofas</a>
                            </li>
                            <li>
                              <a href="#">Decor</a>
                            </li>
                            <li>
                              <a href="#">Furniture</a>
                            </li>
                            <li>
                              <a href="#">Chair</a>
                            </li>
                          </ul>
                        </div> */}
                        {/*=======  End of single sidebar widget  =======*/}
                      </div>
                      {/*=======  End of page sidebar wrapper  =======*/}
                    </div>

                    <div class="col-lg-9 order-md-1">
                      {/*=======  blog page content  =======*/}
                      <PostDetails
                        data={blogPost}
                        relatedPost={relatedPost}
                        blogPostLoading={blogPostLoading}
                        relatedPostLoading={relatedPostLoading}
                      />
                      {/*=======  End of blog page content  =======*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {AddLibrary("/assets/js/vendors.js")}
      {AddLibrary("/assets/js/active.js")}
    </>
  );
};

export default BlogPost;
