import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../config";
import { toast } from "react-toastify";
import NewsLater from "../components/NewsLater";
import Header from "../layouts/Header";
const clientId =
  "1041146774583-eogmiftqgbekc7r62uocdrm5gk7uumco.apps.googleusercontent.com";

const ThankYou = () => {
  const thankYouScrollRef = useRef(null);

  const [contactDetails, setContactDetails] = useState({});
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    fetch(`${SERVER_URL}/settings`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          //   console.log(result);
          if (result.status == 200) {
            // toast.success(result.message);
            setContactDetails(result.body.contactUs || {});
            // console.log(result.body);
          } else {
            const keys = Object.keys(result.errors);
            for (let i = 0; i < keys.length; i++) {
              toast.error(result.errors[keys[i]]);
            }
            toast.error(result.message);
          }
          setDataLoading(false);
          setDataLoading(false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          setDataLoading(false);
        }
      );
  }, []);

  // Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
    window.fbq("track", "Purchase", { value: 1000.0, currency: "INR" });
  }, []);

  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      {/* <Breadcrumb title="Thank You" page={"thank-you"} /> */}
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div className="page-content-area mt-5" ref={thankYouScrollRef}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div className="page-wrapper">
                <div className="page-content-wrapper">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-6 m-auto">
                      {/* Login Form s*/}
                      <div className="text-center">
                        <img
                          style={{ width: "200px" }}
                          src="/assets/img/thank-you.png"
                          className="card-img"
                          alt=""
                        />
                      </div>
                      <div className="text-center">
                        <h1 className="h1 my-5 font-weight-bold">
                          Thank you for your order!{" "}
                        </h1>
                        <p className="h6">Your order will be Confirmed Soon!</p>
                        <p className="h6">
                          Don't hesitate to contact us at{" "}
                          {contactDetails.supportEmail}
                        </p>
                      </div>

                      <div className="text-center mt-5 ">
                        <Link
                          style={{
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                          to={"/"}
                          className="btn btn-success px-3 py-2 mr-2"
                        >
                          Home
                        </Link>
                        {/* <Link
                          to={"/account/myAccount/orders"}
                          className="btn btn-success px-3 py-2  ml-3"
                        >
                          My Order
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <NewsLater />
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default ThankYou;
