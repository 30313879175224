import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { testimonialSliderSetting } from "../../helpers/slider";
import { SERVER_URL } from "../../config";
import { toast } from "react-toastify";
import TestimonialCard from "./TestimonialCard";

const TestimonialContainer = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialLoading, setTestimonialLoading] = useState(false);
  // Get All Testimonial
  useEffect(() => {
    setTestimonialLoading(true);
    fetch(`${SERVER_URL}/testimonials?skip=0&limit=10`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTestimonialLoading(false);
        if (data.status == 200) {
          setTestimonials(data.body || []);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setTestimonialLoading(false);
        toast.warning(error);
      });
  }, []);
  return (
    <div className="testimonial-area section-space">
      <div className="container wide">
        <div className="row">
          <div className="col-lg-12">
            <div className="full-testimonial-wrapper testimonial-bg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/*-=======  testimonial wrapper  =======*/}
                    <div className="testimonial-wrapper section-space--inner">
                      <div className="ht-slick-slider">
                        {/*-=======  single testimonial item  =======*/}

                        <Slider {...testimonialSliderSetting}>
                          {testimonials.map((item, index) => {
                            return (
                              <TestimonialCard item={item} index={index} />
                            );
                          })}
                        </Slider>
                        {/*-=======  End of single testimonial item  =======*/}
                      </div>
                    </div>
                    {/*-=======  End of testimonial wrapper  =======*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialContainer;
