import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { SERVER_URL } from "../../config";
import ProductSkeletonLoader from "../ProductSkeletonLoader";

const CategoryGridContainer = () => {
  const [parCategories, setParentcategories] = useState([]);
  const [parCatLoading, setParCatLoading] = useState(true);
  // Get All Parent Categoies
  useEffect(() => {
    setParCatLoading(true);
    fetch(`${SERVER_URL}/parentCategories`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setParCatLoading(false);
        if (data.status == 200) {
          const sortedData = data.body.sort((a, b) =>
            a.position > b.position ? 1 : -1
          );
          setParentcategories(sortedData || []);
          // console.log("Par Category", data.body);
        } else {
          // toast.warning(data.message);
        }
        // console.log("Success:", data);
      })
      .catch((error) => {
        setParCatLoading(false);
        // toast.warning(error);
      });
  }, []);
  return (
    <div className="category-area section-space">
      <div className="container wide">
        <div className="row">
          <div className="col-lg-12">
            <div className="category-wrapper">
              <div className="row row-10 masonry-category-layout--style2">
                {/* <div className="col grid-item2">
                  <div className="single-category-item">
                    <div className="single-category-item__image">
                      <Link
                        to={
                          parCategories.length > 0 ? parCategories[0].slug : ""
                        }
                      >
                        <img
                          width="375"
                          height="550"
                          src="assets/img/category/cat1.jpg"
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-category-item__content">
                      <h3 className="title">
                        {parCategories.length > 0 ? parCategories[0].name : ""}
                      </h3>
                      <Link
                        to={
                          parCategories.length > 0 ? parCategories[0].slug : ""
                        }
                      >
                        Shop Now{" "}
                        <i className="ion-android-arrow-dropright-circle"></i>
                      </Link>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col grid-item2--width2">
                  <div className="single-category-item">
                    <div className="single-category-item__image">
                      <Link
                        to={
                          parCategories.length > 1 ? parCategories[1].slug : ""
                        }
                      >
                        <img
                          width="709"
                          height="242"
                          src="assets/img/category/cat3.jpg"
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-category-item__content">
                      <h3 className="title">
                        {parCategories.length > 1
                          ? parCategories[1].name
                          : "Cleaner"}
                      </h3>
                      <Link
                        to={
                          parCategories.length > 1 ? parCategories[1].slug : ""
                        }
                      >
                        Shop Now{" "}
                        <i className="ion-android-arrow-dropright-circle"></i>
                      </Link>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col grid-item2">
                  <div className="single-category-item">
                    <div className="single-category-item__image">
                      <Link
                        to={
                          parCategories.length > 2
                            ? parCategories[2].slug
                            : "Storage"
                        }
                      >
                        <img
                          width="380"
                          height="265"
                          src="assets/img/category/cat2.jpg"
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-category-item__content">
                      <h3 className="title">
                        {parCategories.length > 2
                          ? parCategories[2].name
                          : "Lightning"}
                      </h3>
                      <Link
                        to={
                          parCategories.length > 2 ? parCategories[2].slug : ""
                        }
                      >
                        Shop Now{" "}
                        <i className="ion-android-arrow-dropright-circle"></i>
                      </Link>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col grid-item2">
                  <div className="single-category-item">
                    <div className="single-category-item__image">
                      <Link
                        to={
                          parCategories.length > 3 ? parCategories[3].slug : ""
                        }
                      >
                        <img
                          src="assets/img/category/cat6.jpg"
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-category-item__content">
                      <h3 className="title">
                        {parCategories.length > 3
                          ? parCategories[3].name
                          : "Decoration"}
                      </h3>
                      <Link
                        to={
                          parCategories.length > 3 ? parCategories[3].slug : ""
                        }
                      >
                        Shop Now{" "}
                        <i className="ion-android-arrow-dropright-circle"></i>
                      </Link>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col grid-item2--width2">
                  <div className="single-category-item">
                    <div className="single-category-item__image">
                      <Link
                        to={
                          parCategories.length > 4 ? parCategories[4].slug : ""
                        }
                      >
                        <img
                          width="709"
                          height="242"
                          src="assets/img/category/cat4.jpg"
                          className="img-fluid"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-category-item__content">
                      <h3 className="title">
                        {parCategories.length > 4
                          ? parCategories[4].name
                          : "Accessories"}
                      </h3>
                      <Link
                        to={
                          parCategories.length > 4 ? parCategories[4].slug : ""
                        }
                      >
                        Shop Now{" "}
                        <i className="ion-android-arrow-dropright-circle"></i>
                      </Link>
                    </div>
                  </div>
                </div> */}
                {parCatLoading
                  ? [...Array(4)].map(() => {
                      return (
                        <div className="col-md-3">
                          <ProductSkeletonLoader />
                        </div>
                      );
                    })
                  : ""}

                {parCategories.map((category, index) => {
                  return (
                    <div className="col grid-item2">
                      <div className="single-category-item">
                        <div className="single-category-item__image">
                          <Link to={`/${category.slug}`}>
                            <img
                              // style={{ height: "175px", width: "380px" }}
                              // width="380px"
                              // height="265px"
                              src={
                                category.image != "null" && category.image != ""
                                  ? category.image
                                  : "https://www.testronixinstruments.com/backend/uploads/categorymulti/subcategory/1610607074.jpg"
                              }
                              className="img-fluid"
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="single-category-item__content">
                          <h3 className="title">
                            {" "}
                            {category?.name?.length > 13
                              ? category?.name?.slice(0, 13)
                              : category?.name}{" "}
                          </h3>
                          <Link to={`/${category.slug}`}>
                            Shop Now{" "}
                            <i className="ion-android-arrow-dropright-circle"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryGridContainer;
