import React from "react";
import Header from "../layouts/Header";

const Wishlists = () => {
  return (
    <>
      <Header />
      {/*====================  breadcrumb area ====================*/}
      <div class="breadcrumb-area section-space--half">
        <div class="container wide">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  breadcrumb wrpapper  =======*/}
              <div class="breadcrumb-wrapper breadcrumb-bg">
                {/*=======  breadcrumb content  =======*/}
                <div class="breadcrumb-content">
                  <h2 class="breadcrumb-content__title">Wishlist</h2>
                  <ul class="breadcrumb-content__page-map">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li class="active">Wishlist</li>
                  </ul>
                </div>
                {/*=======  End of breadcrumb content  =======*/}
              </div>
              {/*=======  End of breadcrumb wrpapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}
      {/*====================  page content area ====================*/}
      <div class="page-content-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              {/*=======  page wrapper  =======*/}
              <div class="page-wrapper">
                <div class="page-content-wrapper">
                  <form action="#">
                    {/*=======  cart table  =======*/}

                    <div class="cart-table table-responsive mb-0">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="pro-thumbnail">Image</th>
                            <th class="pro-title">Product</th>
                            <th class="pro-price">Price</th>
                            <th class="pro-quantity">Quantity</th>
                            <th class="pro-subtotal">Total</th>
                            <th class="pro-remove">Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="pro-thumbnail">
                              <a href="single-product.html">
                                <img
                                  width="600"
                                  height="800"
                                  src="assets/img/products/big1-1.webp"
                                  class="img-fluid"
                                  alt="Product"
                                />
                              </a>
                            </td>
                            <td class="pro-title">
                              <a href="single-product.html">
                                Cillum dolore tortor nisl fermentum
                              </a>
                            </td>
                            <td class="pro-price">
                              <span>
                                <i class="fa fa-inr" aria-hidden="true"></i>
                                29.00
                              </span>
                            </td>
                            <td class="pro-quantity">
                              <div class="quantity-selection">
                                <input type="number" value="1" min="1" />
                              </div>
                            </td>
                            <td class="pro-subtotal">
                              <span>
                                <i class="fa fa-inr" aria-hidden="true"></i>
                                29.00
                              </span>
                            </td>
                            <td class="pro-remove">
                              <a href="#">
                                <i class="fa fa-trash-o"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td class="pro-thumbnail">
                              <a href="single-product.html">
                                <img
                                  width="600"
                                  height="800"
                                  src="assets/img/products/big1-2.webp"
                                  class="img-fluid"
                                  alt="Product"
                                />
                              </a>
                            </td>
                            <td class="pro-title">
                              <a href="single-product.html">
                                Auctor gravida pellentesque
                              </a>
                            </td>
                            <td class="pro-price">
                              <span>
                                <i class="fa fa-inr" aria-hidden="true"></i>
                                30.00
                              </span>
                            </td>
                            <td class="pro-quantity">
                              <div class="quantity-selection">
                                <input type="number" value="1" min="1" />
                              </div>
                            </td>
                            <td class="pro-subtotal">
                              <span>
                                <i class="fa fa-inr" aria-hidden="true"></i>
                                60.00
                              </span>
                            </td>
                            <td class="pro-remove">
                              <a href="#">
                                <i class="fa fa-trash-o"></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {/*=======  End of cart table  =======*/}
                  </form>
                </div>
              </div>
              {/*=======  End of page wrapper  =======*/}
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of page content area  ====================*/}
      {/*====================  newsletter area ====================*/}
      <div class="newsletter-area section-space--inner">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="newsletter-wrapper">
                <p class="small-text">Special Ofers For Subscribers</p>
                <h3 class="title">Ten Percent Member Discount</h3>
                <p class="short-desc">
                  Subscribe to our newsletters now and stay up to date with new
                  collections, the latest lookbooks and exclusive offers.
                </p>

                <div class="newsletter-form">
                  <form id="mc-form" class="mc-form">
                    <input
                      type="email"
                      placeholder="Enter Your Email Address Here..."
                      required
                    />
                    <button type="submit" value="submit">
                      SUBSCRIBE
                    </button>
                  </form>
                </div>
                {/* mailchimp-alerts Start */}
                <div class="mailchimp-alerts">
                  <div class="mailchimp-submitting"></div>
                  {/* mailchimp-submitting end */}
                  <div class="mailchimp-success"></div>
                  {/* mailchimp-success end */}
                  <div class="mailchimp-error"></div>
                  {/* mailchimp-error end */}
                </div>
                {/* mailchimp-alerts end */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of newsletter area  ====================*/}
    </>
  );
};

export default Wishlists;
