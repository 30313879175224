import React from "react";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
export default function FacebookSharingButton({ url, quotes, hashtag }) {
  return (
    <div className="social-share-buttons">
      <WhatsappShareButton
        url={url || window.location.href}
        quote={quotes || "Ferra Indica - World is yours to explore"}
        hashtag={hashtag || "#ferraindica"}
        //   className={classes.socialMediaButton}
      >
        {/* <WhatsappIcon size={47} /> */}
        <a href="" className="twitter">
          <i class="fa fa-whatsapp"></i>
        </a>
      </WhatsappShareButton>
    </div>
  );
}
