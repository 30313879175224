import React from "react";

const FeaturedAreaContainer = () => {
  return (
    <div className="feature-logo-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/*-=======  feature logo wrapper  =======*/}
            <div className="feature-logo-wrapper section-space--inner-bottom">
              <div className="row">
                <div className="col-md-4">
                  {/*-=======  single feature logo  =======*/}
                  <div className="single-feature-logo">
                    <div className="single-feature-logo__image">
                      <img
                        width="51"
                        height="52"
                        src="assets/img/icons/free_shipping.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="single-feature-logo__content">
                      <h4 className="title">FREE SHIPPING</h4>
                      <p className="short-desc">
                        We offer free shipping via Standard Shipping on orders
                        over Rs 1000
                      </p>
                    </div>
                  </div>
                  {/*-=======  End of single feature logo  =======*/}
                </div>
                <div className="col-md-4">
                  {/*-=======  single feature logo  =======*/}
                  <div className="single-feature-logo">
                    <div className="single-feature-logo__image">
                      <img
                        width="52"
                        height="52"
                        src="assets/img/icons/money_back.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="single-feature-logo__content">
                      <h4 className="title">MONEY BACK GUARANTEE</h4>
                      <p className="short-desc">
                        If you're not satisfied with our product, we'll refund
                        the purchase price*.
                      </p>
                    </div>
                  </div>
                  {/*-=======  End of single feature logo  =======*/}
                </div>
                <div className="col-md-4">
                  {/*-=======  single feature logo  =======*/}
                  <div className="single-feature-logo">
                    <div className="single-feature-logo__image">
                      <img
                        width="41"
                        height="53"
                        src="assets/img/icons/support247.webp"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="single-feature-logo__content">
                      <h4 className="title">ONLINE SUPPORT 24/7</h4>
                      <p className="short-desc">
                        Our friendly support team is available to help you 24
                        hours a day, seven days a week
                      </p>
                    </div>
                  </div>
                  {/*-=======  End of single feature logo  =======*/}
                </div>
              </div>
            </div>
            {/*-=======  End of feature logo wrapper  =======*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedAreaContainer;
