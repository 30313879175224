import React, { useState, useEffect } from "react";
import { herosliderSetting } from "../../helpers/slider";
import Slider from "react-slick";
import { SERVER_URL } from "../../config";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import SliderImage from "./SliderImage";

const HeroSliderContainer = () => {
  const [imageSliders, setImageSliders] = useState([]);
  const [imageSliderLoading, setImageSliderLoading] = useState(false);
  const history = useHistory();
  // Get Image Sliders
  useEffect(() => {
    const localSlider = JSON.parse(localStorage.getItem("slider"));
    if (localSlider) {
      setImageSliders(localSlider);
    } else {
      setImageSliderLoading(true);
    }

    fetch(`${SERVER_URL}/settings`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setImageSliderLoading(false);

        if (data.status == 200) {
          setImageSliders(data.body.slider || []);

          // Set slider to localStorege
          localStorage.setItem("slider", JSON.stringify(data.body.slider));
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setImageSliderLoading(false);
        // toast.warning(error);
      });
  }, []);
  return (
    <div className="hero-slider-area section-space">
      {/*-=======  hero slider wrapper  =======*/}

      <div className="hero-slider-wrapper">
        <div className="ht-slick-slider">
          {/*-=======  single slider item  =======*/}

          <Slider {...herosliderSetting}>
            {imageSliders.map((item, index) => {
              return <SliderImage item={item} key={index} />;
            })}
          </Slider>

          {/*-=======  End of single slider item  =======*/}

          {/*-=======  single slider item  =======*/}

          {/* <div className="single-slider-item">
              <div className="hero-slider-item-wrapper hero-slider-item-wrapper--fullwidth hero-slider-bg-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="hero-slider-content hero-slider-content--left-space">
                        <p className="slider-title slider-title--big-light">
                          AMAZING PRODUCT!
                        </p>
                        <p className="slider-title slider-title--big-light">
                          DECOR CHAIR
                        </p>
                        <p className="slider-title slider-title--small">
                          An elegant selection of chairs combining comfort &
                          practicality. Providing the perfect solution for large
                          & small scale exhibition seating.
                        </p>
                        <a
                          className="hero-slider-button"
                          href="shop-left-sidebar.html"
                        >
                          {" "}
                          <i className="ion-ios-plus-empty"></i> SHOP NOW
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          {/*-=======  End of single slider item  =======*/}

          {/*-=======  single slider item  =======*/}

          {/* <div className="single-slider-item">
              <div className="hero-slider-item-wrapper hero-slider-item-wrapper--fullwidth hero-slider-bg-6">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="hero-slider-content hero-slider-content--left-space">
                        <p className="slider-title slider-title--big-light">
                          New Arrivals
                        </p>
                        <p className="slider-title slider-title--big-light">
                          Decorative Vases
                        </p>
                        <p className="slider-title slider-title--small">
                          The materials used in these exquisite accessories are
                          of the same high quality marbles and granites that are
                          found.
                        </p>
                        <a
                          className="theme-button hero-slider-button"
                          href="shop-left-sidebar.html"
                        >
                          {" "}
                          <i className="ion-ios-plus-empty"></i> SHOP NOW
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          {/*-=======  End of single slider item  =======*/}
        </div>
      </div>

      {/*-=======  End of hero slider wrapper  =======*/}
    </div>
  );
};

export default HeroSliderContainer;
