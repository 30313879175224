import React from "react";

const Loader = ({ text }) => {
  return (
    <span>
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        style={{ height: "11px", width: "11px" }}
        aria-hidden="true"
      ></span>{" "}
      {text == undefined ? "Loading..." : text}
    </span>
  );
};

export default Loader;
