import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { SERVER_URL } from "../config";

const NewsLater = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));

  const submitHandler = (evt) => {
    evt.preventDefault();
    setLoading(true);
    fetch(`${SERVER_URL}/newsletters`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          (customerInfo && customerInfo.jwtToken) || ""
        }`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          //   console.log(result);
          if (result.status == 200) {
            toast.success(result.message);
            setFormData({ email: "" });
          } else {
            const keys = Object.keys(result.errors);
            for (let i = 0; i < keys.length; i++) {
              toast.error(result.errors[keys[i]]);
            }
            toast.error(result.message);
          }
          setLoading(false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          setLoading(false);
        }
      );
  };
  return (
    <div className="newsletter-area section-space--inner">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="newsletter-wrapper">
              <p className="small-text">To get our updates & Offers</p>
              <h3 className="title">Subscribe for Latest Updates</h3>
              <p className="short-desc">
                Subscribe to our newsletters now and stay up to date with new
                collections, the latest lookbooks and exclusive offers.
              </p>

              <div className="newsletter-form">
                {/* <form id="mc-form" className="mc-form" onSubmit={submitHandler}> */}
                <form onSubmit={submitHandler}>
                  <input
                    type="email"
                    placeholder="Enter Your Email Address Here..."
                    value={formData.email}
                    onChange={(evt) => {
                      setFormData({ ...formData, email: evt.target.value });
                    }}
                  />
                  <button type="submit" value="submit">
                    {loading ? <Loader /> : "SUBSCRIBE"}
                  </button>
                </form>
              </div>
              {/* mailchimp-alerts Start */}
              <div className="mailchimp-alerts">
                <div className="mailchimp-submitting"></div>
                {/* mailchimp-submitting end */}
                <div className="mailchimp-success"></div>
                {/* mailchimp-success end */}
                <div className="mailchimp-error"></div>
                {/* mailchimp-error end */}
              </div>
              {/* mailchimp-alerts end */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLater;
