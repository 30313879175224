import React, { useContext, useEffect } from "react";
import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";
import { latestArivalSliderSetting } from "../../helpers/slider";
import { CustomerContext } from "../../Routes";
import Rating from "react-rating";
import { useState } from "react";
import { SERVER_URL } from "../../config";
import { toast } from "react-toastify";
import ProductCard from "../ProductCard";
import ProductSkeletonLoader from "../ProductSkeletonLoader";

const LatestArivalContainer = () => {
  const { state, dispatch } = useContext(CustomerContext);
  const history = useHistory();
  const [latestArival, setLatestArivals] = useState([]);
  const [addedToWishlist, setAddedToWishlist] = useState(false);
  const [myWishlists, setMyWishlists] = useState([]);
  const [productLoaded, setProductLoaded] = useState(false);
  const [removeFromWishlist, setRemoveFromWishlist] = useState(false);
  const [addWishlistLoading, setAddWishlistLoading] = useState(false);
  const [removeWishlistLoading, setRemoveWishlistLoading] = useState(false);
  const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));

  // Get Latest Arival Products
  useEffect(() => {
    // setCategoryLoading(true);
    fetch(`${SERVER_URL}/products`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // setCategoryLoading(false);
        if (data.status == 200) {
          setLatestArivals(data.body || []);
        } else {
          setLatestArivals([]);
          // toast.warning(data.message);
        }
        setProductLoaded(true);
      })
      .catch((error) => {
        // setCategoryLoading(false);
        toast.error(error);
        setProductLoaded(true);
      });
  }, []);

  // Get Wishlists
  useEffect(() => {
    // setProductLoading(true);
    fetch(`${SERVER_URL}/wishlists/myWishlist`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setMyWishlists(data.body || []);
          // console.log(data.body);
        } else {
          // toast.warning(data.message);
        }
      })
      .catch((error) => {
        // setProductLoading(false);
        toast.warning(error);
      });
  }, [addedToWishlist, removeFromWishlist]);

  const addToCartHandler = (fromWhere, product, quantity) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        name: product.name,
        slug: product.slug,
        price: product.sellingPrice,
        sku: product.sku,
        weight: product.weight,
        length: product["length"],
        width: product.width,
        height: product.height,
        diameter: product.diameter,
        capacity: product.capacity,
        packageContent: product.packageContent,
        pileHeight: product.pileHeight,
        mountingType: product.mountingType,
        brand: product.brand._id,
        care: product.care,
        color: product.color,
        description: product.description,
        craftType: product.craftType,
        material: product.material._id,
        shape: product.shape,
        isReturnable: product.isReturnable,
        returnCondition: product.returnCondition,
        parentCategory: product.parentCategory._id,
        subCategory: product.subCategory._id,
        childCategory: product.childCategory._id,
        productId: product._id,
        quantity: quantity || 1,
        image: product.defaultImage,
        maxOrderQuantity: product.maxOrderQuantity,
      },
    });

    if (fromWhere == "BUY_NOW") {
      // history.push("/checkout");
    }
  };

  // Add Product to Wishlist
  const addWishlistHandler = (evt, product) => {
    evt.preventDefault();

    // check customer is logedIn or not
    if (state && state.jwtToken) {
      setAddWishlistLoading(true);
      fetch(`${SERVER_URL}/wishlists`, {
        method: "POST", // or 'PUT'
        body: JSON.stringify({ product: product._id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state ? state.jwtToken : ""}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAddWishlistLoading(false);
          if (data.status == 200) {
            toast.success(data.message);
            setAddedToWishlist(!addedToWishlist);
          } else {
            toast.warning(data.message);
          }
          // console.log("Success:", data);
        })
        .catch((error) => {
          setAddWishlistLoading(false);
          toast.error(error);
        });
    } else {
      toast.warning("Please Login First");
      history.push("/account/login");
    }
  };

  // Remove Product to Wishlist
  const removeWishlistHandler = (evt, wishListId) => {
    evt.preventDefault();
    setRemoveWishlistLoading(true);
    fetch(`${SERVER_URL}/wishlists/${wishListId}`, {
      method: "DELETE", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${customerInfo ? customerInfo.jwtToken : ""}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRemoveWishlistLoading(false);
        if (data.status == 200) {
          toast.success(data.message);
          setRemoveFromWishlist(!removeFromWishlist);
        } else {
          toast.warning(data.message);
        }
      })
      .catch((error) => {
        setRemoveWishlistLoading(false);
        toast.error(error);
      });
  };

  return (
    <div className="single-row-slider-area section-space">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/*-=======  section title  =======*/}
            <div className="section-title-wrapper text-center section-space--half">
              <h2 className="section-title">Latest Arrivals</h2>
              {/* <p className="section-subtitle">
                Shop our newest products and be the first to get your hands on
                the latest arrivals!
              </p> */}
            </div>
            {/*-=======  End of section title  =======*/}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {/*-=======  single row slider wrapper  =======*/}
            <div
            // className="single-row-slider-wrapper"
            >
              <div className="ht-slick-slider">
                {productLoaded ? (
                  latestArival.length ? (
                    <Slider {...latestArivalSliderSetting}>
                      {latestArival.map((product, pIndex) => {
                        // Discound Presentage
                        const discountPercent = Math.ceil(
                          100 -
                            (Number.parseInt(product.sellingPrice) * 100) /
                              Number.parseInt(product.mrp)
                        );

                        // Calculate avarage reviews
                        let avarageRating = 0;
                        const sumOfRatings = product.reviews.reduce(
                          (accumulator, current) => {
                            return accumulator + current.rating;
                          },
                          0
                        );
                        avarageRating = (
                          sumOfRatings / product.reviews.length
                        ).toFixed(1);

                        // Check Item in available in the cart or not
                        let availableInCart = false;

                        if (state && state.cart.length) {
                          let available = state.cart.some((item) => {
                            return item.productId == product._id;
                          });
                          if (available) availableInCart = true;
                        }

                        // Check Item in available in the wishlist or not
                        let availableInWishlist = false;

                        let available = myWishlists.some((item) => {
                          return item.product._id == product._id;
                        });
                        if (available) availableInWishlist = true;
                        // if (myWishlists.length) {
                        // }

                        return (
                          <div className="col-md-3" key={`p-${pIndex}`}>
                            {/*-=======  single grid product  =======*/}
                            <ProductCard
                              availableInCart={availableInCart}
                              availableInWishlist={availableInWishlist}
                              product={product}
                              removeWishlistHandler={removeWishlistHandler}
                              category={product.parentCategory}
                              avarageRating={avarageRating}
                              addToCartHandler={addToCartHandler}
                              addWishlistHandler={addWishlistHandler}
                              addWishlistLoading={addWishlistLoading}
                              myWishlists={myWishlists}
                              removeWishlistLoading={removeWishlistLoading}
                              discountPercent={discountPercent}
                            />
                            {/*-=======  End of single grid product  =======*/}
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <div className="alert alert-danger">No Product Found</div>
                  )
                ) : (
                  <Slider {...latestArivalSliderSetting}>
                    {[...Array(4)].map((_, $) => {
                      return (
                        <div className="col-md-3">
                          <ProductSkeletonLoader key={`post-${$}`} />
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </div>
            {/*-=======  End of single row slider wrapper  =======*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestArivalContainer;
