import React from "react";

const TestimonialCard = ({ item, index }) => {
  return (
    <div key={index} className="single-testimonial-item row">
      <div className="col-lg-8 mx-auto">
        <div className="single-testimonial-item__image">
          <img
            style={{ height: "120px", width: "120px", borderRadius: "60px" }}
            src={
              item.image ||
              "https://www.pngkey.com/png/detail/950-9501315_katie-notopoulos-katienotopoulos-i-write-about-tech-user.png"
            }
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="single-testimonial-item__content">
          <p className="testimonial-text">{item.message}</p>
          <img
            width="29"
            height="22"
            src="assets/img/icons/icon_testimonials.webp"
            alt=""
          />
          <p className="testimonial-author">{item.name}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
